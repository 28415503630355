import React from "react";
import { t } from "../../translations/Translation";
import "../../static/common.css";
import Select from 'react-select';


export default function FilterDropdown({ filterName, placeHolder, isMulti, options, selectedOption, setSelectedOption, required, isDisabled }) {

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      height: 25,
      minHeight: 25,
      overflow:"auto",
    }),
  };

  return (
        <div className="col-md-12 p-0">
            {filterName &&<p className="mb-1 filter-title row m-0">{filterName} {required ? <span className="text-danger mb-0 font-wieght-bold">&nbsp;*</span> : ''}</p>}
            <Select
                placeholder= {placeHolder}
                defaultValue={options !== undefined && options.length === 1 ? options[0] : (selectedOption ? selectedOption : '')}
                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                onChange={setSelectedOption}
                isMulti={isMulti}
                options={options}
                required={required}
                isDisabled={!isDisabled}
                className={filterName ? "filter-scroll" : "filter-scroll-emp"}
                noOptionsMessage = {() => {t("NOT_FOUND")}}
                styles={customStyles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#1fe1258a',
                    primary: '#57BA5A',
                  },
              })}
            />
        </div>
        
  );
}