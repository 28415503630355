import React, { useEffect, useState } from "react";
import { t } from "../../translations/Translation";
import "../../static/common.css";
import PlanChart from "../atoms/PlanChart";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { GetPlansByDate } from "../../routes/ApiEndPoints";
import { GetReversedDate } from "../../utilities/CommonFunctions";
import PublicHolidayIcon from "../../static/icons/belgium.svg";


export default function DayTab(props) {

    const times = ["0", "2", "4", "6", "8", "10", "12", "14", "16", "18", "20", "22"];
    const [data, setData] = useState();
    const date = localStorage.getItem('date');
    const regex = /(<([^>]+)>)/ig;
    const [holidayMessage, setHolidayMessage] = useState('');
    const [PublicHolidayIcon, setPublicHolidayIcon] = useState('');

    useEffect(() => {
        setData([])
        const date = localStorage.getItem('date');
        let wids = {};
        let employee_types = {};
        if (localStorage.getItem('workstation') !== '' && localStorage.getItem('workstation') !== undefined && localStorage.getItem('workstation') !== 'null' && localStorage.getItem('workstation') !== null){
            let wsData = JSON.parse(localStorage.getItem('workstation'));
            for (let i=0; i<=wsData.length-1;i++){
                wids[i] = (wsData[i].value)
            }
        }
        if (localStorage.getItem('employee_type') !== '' && localStorage.getItem('employee_type') !== undefined && localStorage.getItem('employee_type') !== 'null' && localStorage.getItem('employee_type') !== null){
            let etData = JSON.parse(localStorage.getItem('employee_type'));
            for (let i=0; i<=etData.length-1;i++){
                employee_types[i] = (etData[i].value)
            }
        }

        if (localStorage.getItem('location')){
            let RequestData = {
                "date" : GetReversedDate(date),
                "lid"  : JSON.parse(localStorage.getItem('location')).value,
                "wids" : JSON.stringify(wids),
                "employee_types": JSON.stringify(employee_types),
                "token": localStorage.getItem('token')
            }
            AXIOS.service(GetPlansByDate, 'POST', RequestData)
            .then((result)=>{
                if (result && result.status === 200 && result.data) {
                    setData(result.data.Plan_data);
                    setHolidayMessage(result.data.public_holiday ? result.data.message : '');
                    setPublicHolidayIcon(result.data.public_holiday_icon);
                } else if (result && result.status === 401){
                    props.CheckAuth();
                } else {
                    setData([]);
                }
            })
            .catch((error) => {
                console.log(error);
            })
        }
    }, [props.appliedFilterStatus, date, props.refreshStatus])


    return (
        <section>
            <div className="col-md-11 d-flex mx-auto bg-white px-4 pb-1">
            <div className="d-flex justify-content-start col-md-6 mx-auto bg-white px-0">
                {holidayMessage && <img src={PublicHolidayIcon} className="delete-icon" title={holidayMessage}></img>}
            </div>
            <div className="d-flex justify-content-end col-md-6 mx-auto bg-white px-0">
                {/* {holidayMessage && <p className="mx-auto text-danger mb-0 font-weight-bold">{holidayMessage}</p>} */}
                <div className='box available mt-2'></div>
                &nbsp;&nbsp; 
                <small className="mt-2">{t('PLANNING_TIME')}</small> 
                <div className='box ml-3 not-available mt-2'></div>
                &nbsp;&nbsp; 
                <small className="mt-2">{t('LEAVE')}</small>
            </div>
            </div>
            <div className="col-md-11 mx-auto bg-white px-4 pb-4 mb-5 pt-0">
                <table className="table m-0">
                    <thead>
                        <tr className="col-md-12 p-0">
                            <th className="col-md-1 p-0 py-3 day-header text-center text-vertical-center workstation-border emp-name">{t('EMPLOYEE')}</th>
                            <th className="d-flex display-inline px-0 py-3 border-top-0 border-right mx-auto">
                            {Object.keys(times).map((key) => {
                                return(
                                    <p key={key} className="mb-0 px-0 py-0 timings">{times[key]}</p>
                                )}
                            )}
                            <p className="mb-0 px-0 py-0 text-left">24</p>
                            </th>
                        </tr>
                    </thead>
                    {data !== undefined && data.length !== 0 && <tbody className="day-overview">
                        {Object.keys(data).map((key) => {
                            return(
                                <tr key={key} className="col-md-12 p-0">
                                    <th className="border-right workstation-width col-md-1 p-0 text-center text-vertical-center emp-name"><small className="text-dark font-weight-bold text-center text-vertical-center" title={data[key].employee_name}>{data[key].employee_name.length > 10 ? data[key].employee_name.replace(regex, '').substring(0,8)+'..': data[key].employee_name}</small></th>
                                    <td className="px-0 py-1 my-auto col-md-11 p-0">
                                    <PlanChart Plans={data[key].chartdata} refreshStatus={props.refreshStatus}></PlanChart>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>}
                </table>
                {data === undefined || (data !== undefined && data.length === 0) && <div className="col-md-12 border border-dark border-top-0 text-center"><p className="pt-3 text-danger">{t("NO_PLAN")}</p></div>}
            </div>
        </section>
    );
}