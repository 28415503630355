import React, {useState, useEffect} from 'react';
import "../../static/common.css";
import Filters from "../molecules/Filters";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { GetWorkstations, GetLocations, GetEmployeeTypes } from "../../routes/ApiEndPoints";


export default function FiltersForCompany(props) {

    const [workStationOptions, setWorkStationOptions] = useState([]);
    const [employeeTypesOptions, setEmployeeTypesOptions] = useState([]);
    const [locationOptions, setLocationsOptions] = useState();
    const [workStationStatus, setWorkStationStatus] = useState(false);

    const [selectedWorkStation, setSelectedWorkstations] = useState(localStorage.getItem('workstation') !== (undefined || '') ? JSON.parse(localStorage.getItem('workstation')): []);
    const [selectedEmployeeTypes, setSelectedEmployeeType] = useState(localStorage.getItem('employee_type') ? JSON.parse(localStorage.getItem('employee_type')): []);
    const [selectedLocations, setSelectedLocations] = useState(localStorage.getItem('location') ? JSON.parse(localStorage.getItem('location')): []);

    useEffect(() => {
        let ListRequestData = {
            "token": localStorage.getItem('token')
        }

        AXIOS.service(GetLocations, "POST", ListRequestData)
        .then(result => {
            setLocationsOptions(result.data);
            if (result && result !== [] && result.status === 200 && result.data) {
                if (result.data.length === 1){
                    localStorage.setItem('location', JSON.stringify(result.data[0]));
                }
            } else {
                setLocationsOptions([]);
            }
        })
        .catch(error => {
            // console.log(error);
        })

        AXIOS.service(GetWorkstations, "POST", ListRequestData)
        .then(result => {
            if (result && result !== [] && result.status === 200) {
                for ( let i=0; i<=result.data.length-1; i++ ){
                    if (result.data[i].location_id === JSON.parse(localStorage.getItem('location')).value) {
                        localStorage.setItem('configData', JSON.stringify(result.data[i].config_data))
                        setWorkStationOptions(result.data[i].workstations !== null ? result.data[i].workstations : []);
                    }
                }
            } else {
                setWorkStationOptions([]);
            }
        })
        .catch(error => {
            // console.log(error);
        })

        AXIOS.service(GetEmployeeTypes, "POST", ListRequestData)
        .then(result => {
            if (result && result !== [] && result.status && result.status === 200) {
                setEmployeeTypesOptions(result.data);
            } else {
                setEmployeeTypesOptions([]);
            }
        })
        .catch(error => {
            // console.log(error);
        })
        
    }, [workStationStatus])

    const setSelectedOptions = (data, type) => {
        if (type === 1){
          setSelectedWorkstations(data);
        }else if (type === 2){
          setSelectedEmployeeType(data);
        }else{
          setSelectedLocations(data);
          localStorage.setItem('location', JSON.stringify(data));
          setWorkStationStatus(!workStationStatus);
          
        }
    }

    const ApplyFilters = () => {
      localStorage.setItem('location', JSON.stringify(selectedLocations));
      localStorage.setItem('workstation', JSON.stringify(selectedWorkStation));
      localStorage.setItem('employee_type', JSON.stringify(selectedEmployeeTypes));
      props.addFilters();
    }

    return (
          <section>
              <div className="col-md-11 mx-auto my-1 p-0">
                    <Filters 
                        locationOptions = {locationOptions} 
                        workStationOptions = {workStationOptions} 
                        employeeTypeOptions = {employeeTypesOptions} 
                        setSelectedOptions={setSelectedOptions} 
                        ApplyFilters={ApplyFilters} 
                    ></Filters>
              </div>
          </section>
    );
}