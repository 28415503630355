import React from 'react';
import '../static/common.css';


export default function TimePicker(props) {
  

  return (
    <table className="time-picker ui-timepicker-table ui-widget-content ui-corner-all">
        <tbody>
            <tr>
                <td className="ui-timepicker-hours">
                    <div className="ui-timepicker-title ui-widget-header ui-helper-clearfix ui-corner-all">Hour</div>
                    <table className="ui-timepicker">
                        <tbody>
                            <tr>
                                <td onClick={() => props.setHourMin('00', props.type, props.index, 1)}>
                                    <a className={props.hour !== '00' ? "ui-state-default" : "ui-state-active"}>00</a>
                                </td>
                                <td onClick={() => props.setHourMin('01', props.type, props.index, 1)}>
                                    <a className={props.hour !== '01' ? "ui-state-default" : "ui-state-active"}>01</a>
                                </td>
                                <td onClick={() => props.setHourMin('02', props.type, props.index, 1)}>
                                    <a className={props.hour !== '02' ? "ui-state-default" : "ui-state-active"}>02</a>
                                </td>
                                <td onClick={() => props.setHourMin('03', props.type, props.index, 1)}>
                                    <a className={props.hour !== '03' ? "ui-state-default" : "ui-state-active"}>03</a>
                                </td>
                                <td onClick={() => props.setHourMin('04', props.type, props.index, 1)}>
                                    <a className={props.hour !== '04' ? "ui-state-default" : "ui-state-active"}>04</a>
                                </td>
                                <td onClick={() => props.setHourMin('05', props.type, props.index, 1)}>
                                    <a className={props.hour !== '05' ? "ui-state-default" : "ui-state-active"}>05</a>
                                </td>
                            </tr>
                            <tr>
                                <td onClick={() => props.setHourMin('06', props.type, props.index, 1)}>
                                    <a className={props.hour !== '06' ? "ui-state-default" : "ui-state-active"}>06</a>
                                </td>
                                <td onClick={() => props.setHourMin('07', props.type, props.index, 1)}>
                                    <a className={props.hour !== '07' ? "ui-state-default" : "ui-state-active"}>07</a>
                                </td>
                                <td onClick={() => props.setHourMin('08', props.type, props.index, 1)}>
                                    <a className={props.hour !== '08' ? "ui-state-default" : "ui-state-active"}>08</a>
                                </td>
                                <td onClick={() => props.setHourMin('09', props.type, props.index, 1)}>
                                    <a className={props.hour !== '09' ? "ui-state-default" : "ui-state-active"}>09</a>
                                </td>
                                <td onClick={() => props.setHourMin('10', props.type, props.index, 1)}>
                                    <a className={props.hour !== '10' ? "ui-state-default" : "ui-state-active"}>10</a>
                                </td>
                                <td onClick={() => props.setHourMin('11', props.type, props.index, 1)}>
                                    <a className={props.hour !== '11' ? "ui-state-default" : "ui-state-active"}>11</a>
                                </td>
                            </tr>
                            <tr>
                                <td onClick={() => props.setHourMin('12', props.type, props.index, 1)}>
                                    <a className={props.hour !== '12' ? "ui-state-default" : "ui-state-active"}>12</a>
                                </td>
                                <td onClick={() => props.setHourMin('13', props.type, props.index, 1)}>
                                    <a className={props.hour !== '13' ? "ui-state-default" : "ui-state-active"}>13</a>
                                </td>
                                <td onClick={() => props.setHourMin('14', props.type, props.index, 1)}>
                                    <a className={props.hour !== '14' ? "ui-state-default" : "ui-state-active"}>14</a>
                                </td>
                                <td onClick={() => props.setHourMin('15', props.type, props.index, 1)}>
                                    <a className={props.hour !== '15' ? "ui-state-default" : "ui-state-active"}>15</a>
                                </td>
                                <td onClick={() => props.setHourMin('16', props.type, props.index, 1)}>
                                    <a className={props.hour !== '16' ? "ui-state-default" : "ui-state-active"}>16</a>
                                </td>
                                <td onClick={() => props.setHourMin('17', props.type, props.index, 1)}>
                                    <a className={props.hour !== '17' ? "ui-state-default" : "ui-state-active"}>17</a>
                                </td>
                            </tr>
                            <tr>
                                <td onClick={() => props.setHourMin('18', props.type, props.index, 1)}>
                                    <a className={props.hour !== '18' ? "ui-state-default" : "ui-state-active"}>18</a>
                                </td>
                                <td onClick={() => props.setHourMin('19', props.type, props.index, 1)}>
                                    <a className={props.hour !== '19' ? "ui-state-default" : "ui-state-active"}>19</a>
                                </td>
                                <td onClick={() => props.setHourMin('20', props.type, props.index, 1)}>
                                    <a className={props.hour !== '20' ? "ui-state-default" : "ui-state-active"}>20</a>
                                </td>
                                <td onClick={() => props.setHourMin('21', props.type, props.index, 1)}>
                                    <a className={props.hour !== '21' ? "ui-state-default" : "ui-state-active"}>21</a>
                                </td>
                                <td onClick={() => props.setHourMin('22', props.type, props.index, 1)}>
                                    <a className={props.hour !== '22' ? "ui-state-default" : "ui-state-active"}>22</a>
                                </td>
                                <td onClick={() => props.setHourMin('23', props.type, props.index, 1)}>
                                    <a className={props.hour !== '23' ? "ui-state-default" : "ui-state-active"}>23</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td className="ui-timepicker-minutes">
                    <div className="ui-timepicker-title ui-widget-header ui-helper-clearfix ui-corner-all">Minute</div>
                    <table className="ui-timepicker">
                        <tbody>
                            {/* <tr>
                                <td onClick={() => props.setHourMin('00', props.type, props.index, 2)}>
                                    <a className={props.minute !== '00' ? "ui-state-default" : "ui-state-active"}>00</a>
                                </td>
                            </tr> */}
                            <tr>
                                <td onClick={() => props.setHourMin('12', props.type, props.index, 2)}>
                                    <a className={props.minute !== '12' ? "ui-state-default" : "ui-state-active"}>12</a>
                                </td>
                            </tr>
                            <tr>
                                <td onClick={() => props.setHourMin('24', props.type, props.index, 2)}>
                                    <a className={props.minute !== '24' ? "ui-state-default" : "ui-state-active"}>24</a>
                                </td>
                            </tr>
                            <tr>
                                <td onClick={() => props.setHourMin('36', props.type, props.index, 2)}>
                                    <a className={props.minute !== '36' ? "ui-state-default" : "ui-state-active"}>36</a>
                                </td>
                            </tr>
                            <tr>
                                <td onClick={() => props.setHourMin('48', props.type, props.index, 2)}>
                                    <a className={props.minute !== '48' ? "ui-state-default" : "ui-state-active"}>48</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
  );
}