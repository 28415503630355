import React, { useState } from "react";
import { RedirectToDayTab } from "../../utilities/CommonFunctions";
import { t } from "../../translations/Translation";
import '../../static/common.css';
import WorkstationIcon from '../../static/icons/Workstation.png';
// import PublicHolidayIcon from "../../static/icons/belgium.svg";

export default function WeekHeader({days , addTableRows, publicHolidays, PublicHolidayIcon}){
    const weekDays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

    return(
        <tr className="border-bottom-0 week-header">
            <th className="text-center text-vertical-center border-top-0 workstation-border workstation-width"><img className="delete-icon my-2" src={WorkstationIcon}  alt="add-icon" title={t("WORKSTATIONS")}></img></th>
            
            <div className="p-0 main-width row m-0 pad-right">
            <th className="px-0 my-0 employee-width text-center text-vertical-center border-top-0 "><p className="mt-3">{t('EMPLOYEE')}</p></th>
            {
                days.map((val, key)=>{
                    return(
                        
                        <th key={val} onClick={() => RedirectToDayTab(val)} className="px-0 date-width text-center text-vertical-center border-top-0 ">
                            <a className="text-dark pointer">{t(weekDays[key])}</a><br></br>
                            <a className="text-dark pointer">{val}</a>
                            <br></br>
                            {publicHolidays[val] !== undefined && <img className="delete-icon" src={PublicHolidayIcon} title={publicHolidays[val]}></img>}
                        </th>
                        
                    )
                })
                
            }
            <th className="total-width text-center text-vertical-center border-top-0 "><p className="mt-3">{t('TOTAL')}</p></th>
            <th className="delete-width px-0 text-center text-vertical-center border-top-0 border-right-0"><p className="mt-3">{t('ACTION')}</p></th>
            </div>
            
        </tr>
    )
}