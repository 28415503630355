import React, {useEffect, useState} from "react";
import {t} from "../../translations/Translation";
import DatePicker from "react-multi-date-picker"
import Button from 'react-bootstrap/Button';
import FilterDropdown from "./FilterDropdown";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { GetHolidayCodes, GetPlansForLeave } from "../../routes/ApiEndPoints";
import { GetReversedDate } from "../../utilities/CommonFunctions";

export default function AddLeaveForm(props){
    const [datesArray, setDatesArray] = useState([]);
    const [date, setDate] = useState(props.updateLeaveData.length === 0 ? '': props.updateLeaveData.leave_from_date );
    const [toDate, setToDate] = useState(props.updateLeaveData.length === 0 && !props.updateLeaveData.multiple_days ? '' : props.updateLeaveData.leave_to_date );

    const [selectedEmployee, setSelectedEmployee] = useState(props.updateLeaveData.length === 0 ? '': props.updateLeaveData.employee);
    const [singleHolidayCode, setSingleHolidayCode] = useState(props.updateLeaveData.length !== 0 && props.updateLeaveData.holiday_code !== undefined ? props.updateLeaveData.holiday_code : "");
    const [morningHolidayCode, setMorningHolidayCode] = useState(props.updateLeaveData.length !== 0 && props.updateLeaveData.half_day_holiday_codes.morning ? props.updateLeaveData.half_day_holiday_codes.morning : "");
    const [eveningHolidayCode, setEveningHolidayCode] = useState(props.updateLeaveData.length !== 0 && props.updateLeaveData.half_day_holiday_codes.evening ? props.updateLeaveData.half_day_holiday_codes.evening : "");

    const [holidayCode, setHolidayCode] = useState([]);
    const [holidayCodesList, setHolidayCodesList] = useState([]);
    const [hoursHolidayCodesList, setHoursHolidayCodesList] = useState([]);

    const [hoursRow, setHoursRow] = useState(props.updateLeaveData.length === 0 || (props.updateLeaveData.length !== 0 && props.updateLeaveData.multiple_holiday_codes_status === 0) ? [1] : props.updateLeaveData.holiday_code_details)

    const [multipleStatus, setMultiplestatus] = useState(props.updateLeaveData.length === 0 ? false : props.updateLeaveData.multiple_days);
    const [getPlansStatus, setGetPlansStatus] = useState(false);
    const [holidayCodeStatus, setHolidayCodeStatus] = useState(props.updateLeaveData.length === 0 ? false : props.updateLeaveData.multiple_holiday_codes_status);
    const [halfDayStatus, setHalfDayStatus] = useState((props.updateLeaveData.length !== 0 && props.updateLeaveData.half_day === 0) || (props.updateLeaveData.length === 0) ? false : true);
    const [morningStatus, setMorningStatus] = useState(props.updateLeaveData.length !== 0 && (props.updateLeaveData.half_day === 1 || props.updateLeaveData.half_day === 3) ? true : false);
    const [eveningStatus, setEveningStatus] = useState(props.updateLeaveData.length !== 0 && (props.updateLeaveData.half_day === 2 || props.updateLeaveData.half_day === 3) ? true : false);
    const [reason, setReason] = useState(props.updateLeaveData.length !== 0 ? props.updateLeaveData.reason : '')
    const [hours, setHours] = useState('');
    const [eid, setEid] = useState(props.updateLeaveData.length !== 0 ? props.updateLeaveData.eid : '');
    const [planRows, setPlanRows] = useState(props.updateLeaveData.length !== 0 ? props.updateLeaveData.plans : [])

    useEffect(()=>{
        if (props.updateLeaveData.length !== 0 && multipleStatus !== props.updateLeaveData.multiple_days){
            setDate('');
            setDatesArray([]);
            setToDate('');
            setHolidayCodeStatus(false);
            setHolidayCode('')
            setHoursRow([1])
            props.leaveData['dates'] = []
        }
        if (props.updateLeaveData === undefined || props.updateLeaveData.length === 0){
            setDate('');
            setDatesArray([]);
            setToDate('');
            setHolidayCodeStatus(false);
            setHolidayCode('')
            setHoursRow([1])
            props.leaveData['dates'] = []
        }
    }, [multipleStatus])

    useEffect(() =>{
        if (hoursRow !== undefined && hoursRow.length < 1){
            setHoursRow([...hoursRow, 1]);
        }
    }, [holidayCodeStatus, holidayCode])


    useEffect(() =>{
        let date = props.leaveData['dates'] !== undefined ? props.leaveData['dates'] : props.updateLeaveData.dates
        if (eid && date !== undefined && date.length !== 0 ){
            let data = {
                "lid": JSON.parse(localStorage.getItem('location')).value,
                "eid": eid,
                "dates": JSON.stringify(date),
                "leave_id": props.updateLeaveData.leave_id,
                "token": localStorage.getItem("token")
            }
            AXIOS.service(GetPlansForLeave, "POST", data)
            .then((result)=>{
                if (result && result.status === 200 ){
                    setPlanRows(result.data);
                    result.data.map((val, index) => {
                        SetLeaveData(10, val.plan_id, val.shift_leave)
                    }) 
                }else{
                    setPlanRows([])
                    JSON.parse(props.leaveData['dates'])
                }
            })
            .catch((error)=>{
                JSON.parse(props.leaveData['dates'])
            })
        }
    }, [holidayCodeStatus, halfDayStatus, getPlansStatus, eid])

    useEffect(() => {
        if (props.updateLeaveData.length !== 0){
            GetHolidayCodesByEmployee(props.updateLeaveData.employee.value)
            SetLeaveData(0, props.updateLeaveData.employee.value, 0);

            props.leaveData['half_day'] = props.updateLeaveData.half_day;
            if (props.updateLeaveData.multiple_days){
                SetLeaveData(1, props.updateLeaveData.leave_from_date, 0);
                SetLeaveData(2, props.updateLeaveData.leave_to_date, 0);
            }else{
                let dates = []
                let reversed_dates = []
                props.updateLeaveData.dates.map((val, key) => {
                    dates.push(GetReversedDate(val));
                    reversed_dates.push(val);
                })
                props.leaveData['dates'] = reversed_dates
                setDatesArray(dates);
            }

            if (props.updateLeaveData.multiple_holiday_codes_status){
                let selectedHolidayCodes = props.updateLeaveData.holiday_code_details
                selectedHolidayCodes.map((val, key) => {
                    SetLeaveData(5, val.hours, key);
                    SetLeaveData(6, val.holiday_code.value, key);
                })
            }
            if (props.updateLeaveData.holiday_code){
                SetLeaveData(3, props.updateLeaveData.holiday_code.value, 0);
            }

            if (props.updateLeaveData.half_day !== 0){
                if (props.updateLeaveData.half_day_holiday_codes.morning){
                    SetLeaveData(3, props.updateLeaveData.half_day_holiday_codes.morning.value, 0);
                    setMorningHolidayCode(props.updateLeaveData.half_day_holiday_codes.morning)
                }
                if (props.updateLeaveData.half_day_holiday_codes.evening){
                    SetLeaveData(11, props.updateLeaveData.half_day_holiday_codes.evening.value, 0);
                    setEveningHolidayCode(props.updateLeaveData.half_day_holiday_codes.evening);
                }
            }

            SetLeaveData(4, props.updateLeaveData.reason, 0);
        }
    }, [])

    const AddMultipleHolidayCodes = () =>{
        setHoursRow([...hoursRow, 1]);
        setHours('')
    }

    const RemoveMultipleHolidayCodes = () =>{
        const rows = [...hoursRow];
        rows.splice(hoursRow.length-1, 1);
        setHoursRow(rows);

        if (hoursRow.length === holidayCode.length){
            const dataRow = [...holidayCode];
            dataRow.splice(holidayCode.length-1, 1);
            setHolidayCode(dataRow);
            props.leaveData['holiday_codes'] = dataRow;
        }
    }

    const GetHolidayCodesByEmployee = (emp_id) =>{
        // setSelectedEmployee(emp_id);
        let data = {
            "lid": JSON.parse(localStorage.getItem('location')).value,
            "eid": emp_id,
            "token": localStorage.getItem("token")
        }
        AXIOS.service(GetHolidayCodes, "POST", data)
        .then((result)=>{
            if (result && result.status === 200 ){
                setHolidayCodesList(result.data.day_codes);
                setHoursHolidayCodesList(result.data.hour_codes);
            }else{
                setHolidayCodesList([])
                setHoursHolidayCodesList([])
            }
        })
        .catch((error)=>{

        })
    }

    const SetLeaveData = (type, data, index) => {
        switch(type){
            case 0:
                GetHolidayCodesByEmployee(data);
                props.leaveData['eid'] = data;
                setEid(data);
                break;
            case 1:
                if (props.leaveData['dates'] === undefined){props.leaveData['dates'] = []}
                if (multipleStatus) {
                    props.leaveData['from_date'] = (data ? (typeof(data) !== 'string' ? data.format("YYYY-MM-DD") : GetReversedDate(data)):'')
                    props.leaveData['multiple_days'] = 1
                    setDate(data ? (typeof(data) === 'string' ? data : data.format("DD-MM-YYYY")) : '')
                // }else if ((multipleStatus && calendarSelect) || halfDayStatus || holidayCodeStatus ){
                }else{
                    let dates = []
                    // data.map((val, key) => {
                    //     dates[key] = val.format("YYYY-MM-DD")
                    //     // setDatesArray([...datesArray, val.format("DD-MM-YYYY")])
                    // })
                    data.map((val, key) => {
                        dates.push(val.format("YYYY-MM-DD"));
                    })
                    // setDatesArray([...datesArray, val.format("DD-MM-YYYY")])
                    props.leaveData['dates'] = dates
                    props.leaveData['multiple_days'] = 0
                    setGetPlansStatus(!getPlansStatus);
                    // props.leaveData['multiple_days'] = multipleStatus && calendarSelect ? 1 : 0
                }
                // else{
                //     props.leaveData['from_date'] = data ? (typeof(data) !== 'string' ? data.format("YYYY-MM-DD") : GetReversedDate(data)) : ''
                //     props.leaveData['multiple_days'] = 0
                //     setDate(data ? (typeof(data) === 'string' ? data : data.format("DD-MM-YYYY")): '')
                // }
                break;
            case 2:
                setToDate(data);
                props.leaveData['to_date'] = (data ? (typeof(data) !== 'string' ? data.format("YYYY-MM-DD") : GetReversedDate(data)):'')
                props.leaveData['multiple_days'] = 1
                props.leaveData['multiple_holiday_codes_status'] = 0
                break;
            case 3:
                if (halfDayStatus){
                    if (props.leaveData['half_day_holiday_codes'] === undefined) {props.leaveData['half_day_holiday_codes'] = {}}
                    props.leaveData['half_day_holiday_codes']['morning'] = data
                }else{
                    props.leaveData['holiday_code'] = data
                    setSingleHolidayCode(data)
                }
                // setHolidayCode(props.leaveData['holiday_code'])
                break;
            case 4:
                props.leaveData['reason']=data;
                setReason(data)
                break;
            case 5:
                if (props.leaveData['multiple_holiday_codes'] !== undefined){
                    if (typeof(props.leaveData['multiple_holiday_codes']) === 'string') {props.leaveData['multiple_holiday_codes'] = JSON.parse(props.leaveData['multiple_holiday_codes'])}
                    if (props.leaveData['multiple_holiday_codes'][index] !== undefined ){
                        props.leaveData['multiple_holiday_codes'][index]['hours'] = data;
                        props.leaveData['multiple_holiday_codes_status'] = 1
                        setHolidayCode(props.leaveData['multiple_holiday_codes'])
                        setHours(data)
                    } else{
                        props.leaveData['multiple_holiday_codes'][index] = {}; 
                        props.leaveData['multiple_holiday_codes'][index]['hours'] = data;
                        props.leaveData['multiple_holiday_codes_status'] = 1
                        setHolidayCode(props.leaveData['multiple_holiday_codes'])
                        setHours(data)
                    }
                }else{
                    props.leaveData['multiple_holiday_codes']= []
                    props.leaveData['multiple_holiday_codes'][index]= {}
                    props.leaveData['multiple_holiday_codes'][index]['hours'] = data
                    props.leaveData['multiple_holiday_codes_status'] = 1
                    setHolidayCode(props.leaveData['multiple_holiday_codes'])
                    setHours(data)
                }
                break;
            case 6:
                if (props.leaveData['multiple_holiday_codes'] !== undefined){
                    if (typeof(props.leaveData['multiple_holiday_codes']) === 'string') {props.leaveData['multiple_holiday_codes'] = JSON.parse(props.leaveData['multiple_holiday_codes'])}
                    if (props.leaveData['multiple_holiday_codes'][index] !== undefined ){
                        props.leaveData['multiple_holiday_codes'][index]['holiday_code'] = data;
                        props.leaveData['multiple_holiday_codes_status'] = 1
                        setHolidayCode(props.leaveData['multiple_holiday_codes'])
                    } else{
                        props.leaveData['multiple_holiday_codes'][index] = {}; 
                        props.leaveData['multiple_holiday_codes'][index]['holiday_code'] = data;
                        props.leaveData['multiple_holiday_codes_status'] = 1
                        setHolidayCode(props.leaveData['multiple_holiday_codes'])
                    }
                }else{
                    props.leaveData['multiple_holiday_codes'] = []
                    props.leaveData['multiple_holiday_codes'][index]= {}
                    props.leaveData['multiple_holiday_codes'][index]['holiday_code'] = data
                    props.leaveData['multiple_holiday_codes_status'] = 1
                    setHolidayCode(props.leaveData['multiple_holiday_codes'])
                }
                break;
            case 7:
                props.leaveData['half_day']=data ? 1 : 0;
                props.leaveData['half_day_error']=data ? true : false;
                // if (eveningStatus && data){setEveningStatus(!eveningStatus); setMorningStatus(true)}
                setHalfDayStatus(!halfDayStatus);
                if (morningStatus){setMorningStatus(!morningStatus)}
                if (eveningStatus){setEveningStatus(!eveningStatus)}
                break;
            case 8:
                props.leaveData['half_day_error']=false
                if (data && eveningStatus) {
                    props.leaveData['half_day']=3;
                    props.leaveData['multiple_holiday_codes_status'] = 0
                }else if (!data && eveningStatus){
                    props.leaveData['half_day']=2;
                }else if (data && !eveningStatus){
                    props.leaveData['half_day']=1;
                }else{
                    props.leaveData['half_day']=1;
                    props.leaveData['half_day_error']=true
                }
                setMorningStatus(data);
                // setEveningStatus(morningStatus);
                break;
            case 9:
                props.leaveData['half_day_error']=false
                if (data && morningStatus) {
                    props.leaveData['half_day']=3;
                    props.leaveData['multiple_holiday_codes_status'] = 0
                }else if (!data && morningStatus) {
                    props.leaveData['half_day']=1;
                }else if (data && !morningStatus) {
                    props.leaveData['half_day']=2;
                }else{
                    props.leaveData['half_day']=1;
                    props.leaveData['half_day_error']=true
                }
                setEveningStatus(data);
                // setMorningStatus(eveningStatus);
                break;
            case 10:
                if (index && props.leaveData['plan_ids'].includes(data) === false){
                    props.leaveData['plan_ids'] = [...props.leaveData['plan_ids'], data];
                }else if(!index){
                    let list = [...props.leaveData['plan_ids']];
                    list.map((val, num)=>{
                        if (val === data){
                            list.splice(num, 1);
                            props.leaveData['plan_ids'] = list;
                        }
                    })  
                }
                break;
            case 11:
                if (props.leaveData['half_day_holiday_codes']){
                    props.leaveData['half_day_holiday_codes']['evening'] = data
                }else{
                    props.leaveData['half_day_holiday_codes'] = {}
                    props.leaveData['half_day_holiday_codes']['evening'] = data
                }
                break;
        }
    }



    return(
        <form className="p-2 pl-3" >
            <div className="form-row col-12 pl-0 pt-3 planning-form float-left">
                <div className="col-4 title-position">
                    <FilterDropdown 
                        filterName={t("EMPLOYEE")}
                        options={props.names}
                        placeHolder={t("SELECT_EMPLOYEE_PLACEHOLDER")}
                        isMulti={false}
                        selectedOption={selectedEmployee}
                        setSelectedOption={(e) => SetLeaveData(0, e.value, 0)}
                        required={true}
                        isDisabled={props.updateLeaveData.length=== 0? true: false}
                    ></FilterDropdown>
                </div>
            </div>
            <div className="form-row col-12 pl-0 pt-1 planning-form float-left">
                <div className="col-4 title-position">
                    <label htmlFor="date" className="filter-title row m-0 mb-1">{multipleStatus ? t("FROM_DATE") : t("MULTIPLE_DATES")}<p className="text-danger mb-0 font-wieght-bold">&nbsp;*</p></label>
                    <DatePicker 
                        className="custom-calendar"
                        multiple={!multipleStatus}
                        // multiple={calendarSelect || halfDayStatus || holidayCodeStatus}
                        name="date"
                        value={!multipleStatus ? datesArray : date} 
                        // value={calendarSelect || halfDayStatus || holidayCodeStatus ? datesArray : date} 
                        onChange={(date)=>SetLeaveData(1, date, 0)}
                        format="DD-MM-YYYY"
                        weekStartDayIndex={1}
                        locale={localStorage.getItem('active_language')}
                    />
                </div>
                {!!multipleStatus && <div className="col-4 title-position">
                    <label htmlFor="date" className="filter-title row m-0 mb-1">{t("TO_DATE")}<p className="text-danger mb-0 font-wieght-bold">&nbsp;*</p></label>
                    <DatePicker 
                        className="custom-calendar"
                        value={toDate} 
                        onChange={(e)=>SetLeaveData(2, e, 0)}
                        format="DD-MM-YYYY"
                        weekStartDayIndex={1}
                        locale={localStorage.getItem('active_language')}
                    />
                </div>}
                {!halfDayStatus && !holidayCodeStatus && <div className="col-4 title-position">
                    <FilterDropdown 
                        filterName={t("HOLIDAY_CODES")}
                        options={holidayCodesList}
                        placeHolder={t("HOLIDAY_CODES_PLACEHOLDER")}
                        isMulti={false}
                        selectedOption={singleHolidayCode}
                        setSelectedOption={(e) => SetLeaveData(3, e.value, 0)}
                        required={true}
                        isDisabled={true}
                    >
                    </FilterDropdown>
                </div>}
                {halfDayStatus && morningStatus &&<div className="col-4 title-position">
                    <FilterDropdown 
                        filterName={t("MORNING_HOLIDAY_CODE")}
                        options={holidayCodesList}
                        placeHolder={t("HOLIDAY_CODES_PLACEHOLDER")}
                        isMulti={false}
                        selectedOption={halfDayStatus ? morningHolidayCode : ''}
                        setSelectedOption={(e) => SetLeaveData(3, e.value, 0)}
                        required={true}
                        isDisabled={true}
                    >
                    </FilterDropdown>
                </div>}
                {halfDayStatus && eveningStatus && <div className="col-4 title-position">
                    <FilterDropdown 
                        filterName={t("EVENING_HOLIDAY_CODE")}
                        options={holidayCodesList}
                        placeHolder={t("HOLIDAY_CODES_PLACEHOLDER")}
                        isMulti={false}
                        selectedOption={halfDayStatus ? eveningHolidayCode : ''}
                        setSelectedOption={(e) => SetLeaveData(11, e.value, 0)}
                        required={true}
                        isDisabled={true}
                    >
                    </FilterDropdown>
                </div>}
                
            </div>
            <br></br>
            <div className="col-12 row top-mar">
            {!multipleStatus && <div className="custom-control custom-checkbox ">
                <input type="checkbox" className="custom-control-input" id="half-day" name="half-day" onChange={() => {SetLeaveData(7, !halfDayStatus, 0)}} checked={halfDayStatus}/>
                <label className="custom-control-label" for="half-day">{t("HALF_DAY")}</label>
            </div>}

            {halfDayStatus !== 0 && halfDayStatus && <div className="ml-3 custom-control custom-checkbox custom-control-inline">
                <input type="checkbox" className="custom-control-input" id="morning" name="morning" onChange={() => {SetLeaveData(8, !morningStatus, 0)}} checked={morningStatus}/>
                <label className="ml-2 custom-control-label green" for="morning">{t("MORNING")}</label>
            </div>}
            {halfDayStatus !== 0 && halfDayStatus && <div className="ml-3 custom-control custom-checkbox custom-control-inline">
                <input type="checkbox" className="custom-control-input" id="evening" name="evening" onChange={() => {SetLeaveData(9, !eveningStatus, 0)}} checked={eveningStatus}/>
                <label className="ml-2 custom-control-label green" for="evening">{t("EVENING")}</label>
            </div>}
            </div>

            
            {!holidayCodeStatus && !halfDayStatus && <div className={"custom-control custom-checkbox mt-2 "}>
                <input type="checkbox" className="custom-control-input" id="multiple-days" name="multiple-days" value={multipleStatus} onChange={() => setMultiplestatus(!multipleStatus)} checked={multipleStatus} />
                <label className="custom-control-label" for="multiple-days">{t("MULTIPLE_DAYS")}</label>
            </div>}
            {!multipleStatus && props.leaveData['half_day'] !== 3 && <div className={"custom-control custom-checkbox mt-2 "}>
                <input type="checkbox" className="custom-control-input" id="multiple-holiday-code" name="multiple-holiday-code" onChange={() => setHolidayCodeStatus(!holidayCodeStatus)} checked={holidayCodeStatus}/>
                <label className="custom-control-label" for="multiple-holiday-code">{t("MULTIPLE_HOLIDAY_CODE")}</label>
            </div>}
            {/* {!!multipleStatus && props.updateLeaveData.length === 0 && <div className="custom-control custom-checkbox mt-2">
                <input type="checkbox" className="custom-control-input" id="multiple-days-calendar" name="multiple-days-calendar" onChange={()=>{setCalendarSelect(!calendarSelect)}} checked={calendarSelect}/>
                <label className="custom-control-label" for="multiple-days-calendar">{t("SELECT_IN_CALENDAR")}</label>
            </div>} */}
            

            {!!holidayCodeStatus && hoursRow !== undefined && props.leaveData['half_day'] !== 3 && hoursRow.map((val, index) => {
                return(
                    <div className="form-row col-12 pl-0 pt-3 pb-1 planning-form float-left" key={val}>
                        <div className="col-4 title-position pr-4">
                            <label htmlFor="hours" className="filter-title row m-0 mb-1">{t("HOURS")}<p className="text-danger mb-0 font-wieght-bold">&nbsp;*</p></label>
                            <input type="text" name="hours" value={holidayCode[index] ? holidayCode[index]['hours'] : hours} onChange={(e)=> SetLeaveData(5, e.target.value, index)} className="form-fields form-control"/>
                        </div>
                        
                        <div className="col-4 title-position">
                            <FilterDropdown 
                                filterName={t("HOLIDAY_CODES")}
                                options={hoursHolidayCodesList}
                                placeHolder={t("HOLIDAY_CODES_PLACEHOLDER")}
                                isMulti={false}
                                selectedOption={props.updateLeaveData.length !== 0 && props.updateLeaveData.multiple_holiday_codes_status  ? val.holiday_code : ''}
                                setSelectedOption={(e) => SetLeaveData(6, e.value, index)}
                                required={true}
                                isDisabled={true}
                            ></FilterDropdown>
                        </div>

                        {index === hoursRow.length-1 &&<div className="col-3 row m-0 my-auto">
                            <Button className='button-style mr-2 mt-1 col-2' onClick={()=>AddMultipleHolidayCodes()}>+</Button>   
                            {hoursRow.length > 1 &&<Button className='button-style mt-1 col-2' onClick={()=>RemoveMultipleHolidayCodes()}>-</Button>}
                        </div>}
                    </div>
                )})
            }
            <br></br>
            {!multipleStatus && (holidayCodeStatus || halfDayStatus) && planRows !== undefined && planRows.length !== 0 && <label htmlFor="plans" className="filter-title mb-1">{t("PLANS")}</label>}
            <div className="col-12 row">
            {!multipleStatus && (holidayCodeStatus || halfDayStatus) && planRows !== undefined && planRows.map((val, index) => {
                return(
                    <div key={val.plan_id} className="custom-control custom-checkbox col-2">
                        <input type="checkbox" className="custom-control-input" id="plan-item" name="plan_item" onChange={(e) => SetLeaveData(10, val.plan_id, e.target.checked)} 
                            defaultChecked={props.updateLeaveData.length !== 0 ? val.shift_leave : ''}/>
                        <label className="custom-control-label" for="plan_item">{val.plan_time}</label>
                    </div>
                )})
            }
            </div>
            <div className="form-group mt-2 mb-0">
                <label for="comment" className="filter-title">{t("REASON")}</label>
                <textarea className="form-control form-fields" rows="5" id="comment" value={reason} onChange={(e) => SetLeaveData(4, e.target.value, 0)}></textarea>
            </div>
        </form>
    )
}