import React, {useEffect, useState} from "react";
import {t} from "../../translations/Translation";
import { checkBreaktime, GetTimeDifference } from "../../utilities/CommonFunctions";
import FilterDropdown from "./FilterDropdown";
import DeleteIcon from "../../static/icons/Delete.svg";
import AddIcon from "../../static/icons/addPlan.png"
import ModalPopup from "../../utilities/popup/Popup";
import TimePicker from "../../utilities/Timepicker";
import { DeleteShift } from "../../routes/ApiEndPoints";
import { APICALL as AXIOS } from "../../services/AxiosServices";


export default function CreateShift({shiftData, defaultValues, DeleteShifts}) {
    const [starttimeVal, setStarttimeValDisplay] = useState(defaultValues !== undefined ? defaultValues.start_time : '');
    const [endtimeVal, setEndtimeValDisplay] = useState(defaultValues !== undefined ? defaultValues.end_time : '');
    const [contrctHours , setContractHours] = useState(defaultValues !== undefined ? defaultValues.contract_hours : '');
    const [breakTime, setBreakTime] = useState(defaultValues !== undefined && defaultValues.break_time !== null ? defaultValues.break_time : '');
    const [warningMessage, setWarningMessage] = useState('');
    const [startPicker, setStartPicker] = useState();
    const [endPicker, setEndPicker] = useState(false);
    const [shifts, setShifts] = useState({});

    const [shiftsArray, setShiftsArray] = useState(defaultValues.shifts!==undefined && defaultValues.shifts.length !== 0 ? defaultValues.shifts : [0]);

    const hideTimePopup = () => {
        setStartPicker()
        setEndPicker()
    }

    const AddNewShift = (key) =>{
        setShiftsArray([...shiftsArray, key+1]);
    }
    
    const Deleteshift = (key) =>{
        if (defaultValues !== undefined && defaultValues.shifts !== undefined && shifts[key] !== undefined && shifts[key].shift_id){
            let data = {
                'shift_id': shifts[key].shift_id,
                "token": localStorage.getItem('token')
            }
            DeleteShifts(data)
        }
        let rows = [...shiftsArray]
        rows.splice(key, 1);
        setShiftsArray(rows);
    }

    useEffect(() => {
        setStartPicker();
        setEndPicker();
        if (defaultValues === undefined){
            setStarttimeValDisplay('');
            setEndtimeValDisplay('');
            setBreakTime('');
            setContractHours('');
        } else {
            setStarttimeValDisplay(defaultValues.start_time);
            setEndtimeValDisplay(defaultValues.end_time);
            setBreakTime(defaultValues.break_time);
            setContractHours(defaultValues.contract_hours);
        }
    }, [defaultValues])

    useEffect(() =>{
        if (defaultValues !== undefined && defaultValues.shifts !== undefined) {
            defaultValues.shifts.map((val, index) => {
                StructureShifts(val.shift_id, 0, index);
                StructureShifts(val.start_time, 1, index);
                StructureShifts(val.end_time, 2, index);
                StructureShifts(val.break_time, 3, index);
                StructureShifts(val.contract_hours, 4, index);
                StructureShifts(defaultValues.wid, 5, index);
            })
            setShiftsArray([...shiftsArray, defaultValues.shifts.length]);
        }
    }, [])


    // Function to set form data into required format for api call
    const StructureShifts = (data, type, index) => {
        if (shifts[index] === undefined) { shifts[index] = {} }
        if (type === 0) {defaultValues !== undefined && defaultValues.shifts !== undefined ? shifts[index].shift_id = data : shifts[index].shift_id = ''}
        
        if (type === 1){
            setStarttimeValDisplay(data);
            shifts[index].start_time = data;
            if (shifts[index].end_time) {
                if (shifts[index].break_time){
                    var contract_hr = (GetTimeDifference(data, shifts[index].end_time) - (shifts[index].break_time/60)).toFixed(2);
                }else{
                    contract_hr = (checkBreaktime(GetTimeDifference(data, shifts[index].end_time))).toFixed(2);
                }
            }
            shifts[index].contract_hours = contract_hr
            setContractHours(contract_hr)

        } else if (type === 2) {
            shifts[index].end_time = data;    
            setEndtimeValDisplay(data);
            if (shifts[index].start_time) {
                if (shifts[index].break_time){
                    var contract_hr = (GetTimeDifference(shifts[index].start_time, data) - (shifts[index].break_time/60)).toFixed(2);
                }else{
                    contract_hr = (checkBreaktime(GetTimeDifference(shifts[index].start_time, data))).toFixed(2);
                }

                shifts[index].contract_hours = contract_hr
                setContractHours(contract_hr)
            }

        }else if (type === 3){
            shifts[index].break_time = data
            setBreakTime(data);
            if (shifts[index].start_time && shifts[index].end_time) {
                let contract_hr = (GetTimeDifference(shifts[index].start_time, shifts[index].end_time) - (data/60)).toFixed(2);
                if (contract_hr < 0) { contract_hr = 0}
                shifts[index].contract_hours = contract_hr
                setContractHours(contract_hr)
            }

        }else if (type === 4){
            shifts[index].contract_hours = data
            setContractHours(data);
        }else if (type === 5){
            shifts[index].workstation_id = (data.value !== undefined? data.value : data)
        }
        if (shifts[index].break_time === undefined){shifts[index].break_time = ""}
        setShifts(shifts)
        shiftData.wid = defaultValues.wid === undefined ? defaultValues : defaultValues.wid
        shiftData.shifts = shifts;
    }    

    
    // Get hour and min separately and combine and display
    const setHourMin = (val, type, index, time) => {
        let hour
        let min
        if (time === 1 && type === 1){
            hour = val
            starttimeVal && starttimeVal.split(':')[1] !== "00" ? min = starttimeVal.split(':')[1] : min="00"
        }else if (time === 2 && type === 1){
            min = val
            starttimeVal && starttimeVal.split(':')[0] !== "00" ? hour = starttimeVal.split(':')[0] : hour="00"
        }else if (time === 1 && type === 2){
            hour = val
            endtimeVal && endtimeVal.split(':')[1] !== "00" ? min = endtimeVal.split(':')[1] : min="00"
        }else {
            min = val
            endtimeVal && endtimeVal.split(':')[0] !== "00" ? hour = endtimeVal.split(':')[0] : hour="00"
        }
        let selTime = hour + ':' + min

        type === 1 ? setStarttimeValDisplay(selTime) : setEndtimeValDisplay(selTime)
        StructureShifts(selTime, type, index)
    }

    return(
        <section>
        {shiftsArray.length !== 0 && shiftsArray.map((val, key) => {
            return(
                <form className="p-2 pl-3" index={key}>
                    <div className="form-row col-11 p-2 py-3 mb-3 border planning-form float-left">
                        <div className="col-3 title-position">
                            <label htmlFor="start_time" onClick={() => hideTimePopup()} className="filter-title row m-0 mb-1">{t('START_TIME')}<p className="text-danger mb-0 font-wieght-bold">&nbsp;*</p></label>
                            {startPicker === key && <TimePicker hour={shifts[key] && shifts[key].start_time? shifts[key].start_time.split(':')[0] :"00"} minute={shifts[key] && shifts[key].start_time ? shifts[key].start_time.split(':')[1] :"00"} index={key} type={1} setHourMin={setHourMin}></TimePicker>}
                            <input type="" name="start_time" onClick={() => {setStartPicker(key); setEndPicker()}} value={shifts[key] ? shifts[key].start_time: ''} onChange={(e)=> StructureShifts(e.target.value, 1, key)} pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" className="form-fields form-control"/>
                        </div>
                        <div className="col-3 title-position">
                            <label htmlFor="end_time" onClick={() => hideTimePopup()} className="filter-title row m-0 mb-1">{t('END_TIME')}<p className="text-danger mb-0 font-wieght-bold">&nbsp;*</p></label>
                            {endPicker === key && <TimePicker hour={shifts[key] && shifts[key].end_time ? shifts[key].end_time.split(':')[0] :"00"} minute={shifts[key] && shifts[key].end_time ? shifts[key].end_time.split(':')[1] :"00"} index={key} type={2} setHourMin={setHourMin}></TimePicker>}
                            <input type="" name="end_time" onClick={() => {setEndPicker(key); setStartPicker();}} value={shifts[key]?shifts[key].end_time:""} onChange={(e)=> StructureShifts(e.target.value, 2, key)}  pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" className="form-fields form-control"/>
                        </div>
                        <div className="col-3 title-position" onClick={() => hideTimePopup()}>
                            <label htmlFor="break_time" className="filter-title mb-1">{t('BREAK_TIME')}</label>
                            <input type="text" maxlength="3" size="3" className="form-fields form-control"  value={shifts[key]?shifts[key].break_time:''} onChange={(e)=> StructureShifts(e.target.value, 3, key)} name="break_time" required ></input>
                        </div>
                        <div className="col-3 title-position" onClick={() => hideTimePopup()}>
                            <label htmlFor="contract_hours" className="filter-title row m-0 mb-1">{t('CONTRACT_HOURS')}<p className="text-danger mb-0 font-wieght-bold">&nbsp;*</p></label>
                            <input type="text" className="form-fields form-control"  value={shifts[key] ? shifts[key].contract_hours : ''} onChange={(e)=> StructureShifts(e.target.value, 4, key)} name="contract_hour" required ></input>
                        </div>
                    </div>
                    { shiftsArray.length - 1 === key && <div className="border form-row col-1 float-right d-flex justify-content-center align-content-center planning-form" onClick={() => hideTimePopup()}><img src={AddIcon} className="image-icon pointer" onClick={() => AddNewShift(key)} alt="add plan"></img></div>}
                    {(shiftsArray.length - 1 !== key) && <div className="border form-row col-1 float-right d-flex justify-content-center align-content-center planning-form" onClick={() => hideTimePopup()}><img src={ DeleteIcon} className="image-icon pointer" onClick={() => Deleteshift(key)} alt="delete plan"></img></div>}
                </form>
            )})
        }
        <br></br>
        </section>
    )
}