import React, { useEffect, useState } from "react";
import Logo from "../../static/icons/ava-logo.png";
import "../../static/common.css";
import "../../static/header.css";
import { useLocation } from "react-router-dom";
import { t } from "../../translations/Translation";


export default function Header(props) {
    const [isEnglish, setIsEnglish] = useState(false);
    const [isDutch, setIsDutch] = useState(false);
    const [isFrench, setIsFrench] = useState(false);
    const [menuStatus, setMenuOpen] = useState(false);
    let location = useLocation();


    useEffect (() => {
        if (location.pathname === '/en'){
            localStorage.setItem('active_language', 'en');
            setIsEnglish(true);
        }else if(location.pathname === '/fr'){
            localStorage.setItem('active_language', 'fr');
            setIsFrench(true);
        }else{
            localStorage.setItem('active_language', 'nl');
            setIsDutch(true);
        }
    })

    return (
        
        <nav className="navbar navbar-default container py-0 col-md-11 px-0" id="navbar" role="banner">
            <div className="container col-md-12 px-0">
                <div className="col-md-12 p-0 region region-navigation">
                    <div className="navbar-header">
                        <a className="logo navbar-btn pull-left pointer" href="/" title={t('HOME')} rel="home">
                            <img  src={Logo} alt={t('HOME')}></img>
                        </a>
                    </div>
                    <section id="menu-icon" className="block clearfix">
                        <div id="default-menu-icon" className="pointer" onClick={() => setMenuOpen(!menuStatus)}></div>
                    </section>
                    <section id="block-homeicon-2" className="block clearfix">
                        <div id="default-home-icon"><a className="pointer" href={localStorage.getItem('domain') + '/' +  localStorage.getItem('active_language') + "/home2"}></a></div>
                    </section>
                    <section id="block-dashboardicon" className="block clearfix">
                        <div id="default-dashboard-icon"><a className="pointer" href={localStorage.getItem('domain') + '/' +  localStorage.getItem('active_language') + "/dashboard"}></a></div>
                    </section>
                    <section id="block-loggedinusername" className="block clearfix font-weight-bold">
                        <div id="loggedin-username"><span>{t('WELCOME')} {props.employee}</span><br></br><span className="Active_company_and_location">{props.company}</span></div>
                    </section>
                    <section className="language-switcher-language-url block clearfix" id="block-languageswitcher" role="navigation">
                        <ul className="links">
                            <li className={isEnglish ?"en is-active" : "en"}><a href={"/en"} className={"language-link" + (isEnglish ? " is-active" : "")} hrefLang="en">EN</a></li>
                            <li className={isDutch ? "nl is-active":"nl"}><a href={"/nl"} className={"language-link" + (isDutch ? " is-active": "")} hrefLang="nl">NL</a></li>
                            <li className={isFrench ? "fr is-active":"fr"}><a href={"/fr"} className={"language-link" + (isFrench ? " is-active": "")} hrefLang="fr">FR</a></li>
                        </ul>
                    </section>
                </div>

                {menuStatus && <nav id="block-wagewatcher-account-menu">
                    <ul className="menu nav navbar-nav mt-0 bg-white navbar-right text-center shadow">
                    <li className="">
                            <a href={localStorage.getItem('domain') + '/' +  localStorage.getItem('active_language') + "/common/myaccount"} className="pointer" title={t('MY_ACCOUNT')}>{t('MY_ACCOUNT')}</a>
                        </li>
                        <li className="">
                            <a href={localStorage.getItem('domain') + '/' +  localStorage.getItem('active_language') + "/ifo"} className="pointer" title={t("TO_BACKEND")} data-drupal-link-system-path="ifo">{t('TO_BACKEND')}</a>
                        </li>
                        <li className="">
                            <a href={localStorage.getItem('domain') + '/' +  localStorage.getItem('active_language') + "/SelectCompany"} data-drupal-link-system-path="SelectCompany" title={t('CHANGE_COMPANY')} className="pointer">{t('CHANGE_COMPANY')}</a>
                        </li>
                        <li className="">
                            <a href={localStorage.getItem('domain') + '/' +  localStorage.getItem('active_language') + "/user/change_password"} title={t('CHANGE_PASSWORD')} data-drupal-link-system-path="user/change_password" className="pointer">{t('CHANGE_PASSWORD')}</a>
                        </li>
                        <li className="">
                            <a href={localStorage.getItem('domain') + '/' +  localStorage.getItem('active_language') + "/user/logout"} data-drupal-link-system-path="user/logout" className="pointer" title={t('LOGOUT')}>{t('LOGOUT')}</a>
                        </li>
                    </ul>
                </nav>}
            </div>
        </nav>    
    );
}