import React, { useEffect, useState } from "react";
import "../../static/common.css";
import TimeIcon from "../../static/icons/Time.png";
import BreakIcon from "../../static/icons/Break.png";
import ContractIcon from "../../static/icons/Contract.png";
import CostIcon from "../../static/icons/Euro.png";
import AddLeaveIcon from "../../static/icons/addLeave.svg";
import SickLeaveIcon from "../../static/icons/medkit.png";
import HolidayIcon from "../../static/icons/sun.png";
import OtherLeaveIcon from "../../static/icons/close.png";
import { GetWeeklyPlansForEmployee } from "../../routes/ApiEndPoints";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import PublicHolidayIcon from "../../static/icons/belgium.svg";

import { t } from "../../translations/Translation";
import ModalPopup from "../../utilities/popup/Popup";

export default function PlanItem({dates, planData, employee_id, showAvailability, deletePublicHoliday, createPlan, workstation, enableShifts, EditLeave}){

    // const [planData, setPlanData] = useState([]);

    // useEffect(()=>{
    //     let requestData = {
    //         'year'       : localStorage.getItem('year'),
    //         'week_number': localStorage.getItem('week_number'),
    //         'lid'        : JSON.parse(localStorage.getItem('location')).value,
    //         "wid"        : workstation,
    //         "employee_types": JSON.stringify(employee_types),
    //         "token"      : localStorage.getItem('token'),
    //         "eid"        : employee_id
    //     }
        
    //     AXIOS.service(GetWeeklyPlansForEmployee, 'POST', requestData)
    //     .then(result => {
    //         if (result && result.status === 200){
    //             setPlanData(result.data);
    //         } else {
    //             setPlanData([]);
    //         }
    //     })
    //     .catch(error => {
    //         // console.log(error);
    //     })
    // }, [dataRefresh, showAvailability, enableShifts, refreshStatus, displayshift, popupState, shiftStatus, appliedFilterStatus, localStorage.getItem('week_number'), localStorage.getItem('year'), localStorage.getItem('location'), addLeaveStatus])

    return (
        dates.map((key, index)=>{
            let shiftLeave = false;
            if (planData[key] !== undefined && planData[key]['day_plans'] !== undefined && planData[key]['day_plans'].length !== 0){
                return(
                    <td key={index} id={planData[key]['leave'] || planData[key]['public_holiday'] ? "leave-background" : ''} className={showAvailability ? (planData[key]['available'] === 'available' ? 'available header-box-size shadow py-2 px-0 pointer border-right' : 
                    (planData[key]['available'] !== 'not_specified' ? 'p-2 not-available header-box-size shadow py-2 pointer px-0 border-right': "header-box-size shadow py-2 px-0 pointer border-right")) : "header-box-size shadow py-2 pointer px-0 border-right"} >
                        {planData[key]['day_plans'].map((value, num) => {
                            if (planData[key]['day_plans'][num].shift_leave) {shiftLeave = true}
                            if (planData[key]['day_plans'][num].start_time)
                                return (
                                    <small key={num} className="mb-0 px-1 pb-1 border-bottom pointer d-flex justify-content-between border_dark plan-item-font" id={planData[key]['day_plans'][num].shift_leave ? "leave-background" : ""} onClick={()=>createPlan(employee_id, key, planData[key]['day_plans'], workstation)}>
                                        <div className="float-left pointer" title={t("PLAN_TIMINGS")}>
                                            <img className="planitem-icons pointer mr-1" src={TimeIcon} alt="start-time"></img>{(planData[key]['day_plans'][num].start_time) + '-' + (planData[key]['day_plans'][num].end_time) }
                                        </div>
                                        &nbsp;
                                        <div className="float-right pointer" title={t("BREAK_TIME")}>
                                            <img className="planitem-icons mr-1 pointer" src={BreakIcon} alt="break-time" ></img>{(planData[key]['day_plans'][num].break_time) ? (planData[key]['day_plans'][num].break_time) : 0}
                                        </div>
                                    </small>
                                )
                        })}
                        <small className={enableShifts &&  planData[key]['day_plans'].length === 1 ? "mb-0 px-1 pt-4 pb-3 d-flex justify-content-between  pointerplan-item-font" : "mb-0 px-1 pt-2 d-flex justify-content-between  pointerplan-item-font"}>
                            {planData[key]['day_plans'].length !== 0 && <div className="float-left pointer" title={t("COST")} onClick={()=>createPlan(employee_id, key, planData[key]['day_plans'], workstation)}>
                                {planData[key]['total_shift_cost'] !== undefined && planData[key]['total_shift_cost'] !== "" &&<img className="planitem-icons mr-1 pointer" src={CostIcon} alt="total-cost"></img>}{planData[key]['total_shift_cost']}
                            </div>}
                            {!!planData[key]['leave'] && 
                                <img 
                                    className={planData[key]['icon'] === "2" ? "leave-sun-icon pointer" : (planData[key]['icon'] === "1" || planData[key]['icon'] === "3" ? "edit-leave-icon pointer": "leave-cancel-icon pointer")} 
                                    src={planData[key]['icon'] === "1" ? SickLeaveIcon : (planData[key]['icon'] === "2" ? HolidayIcon : (planData[key]['icon'] === "3" ? AddLeaveIcon : OtherLeaveIcon))} 
                                    id=""
                                    alt="contract-hors" 
                                    title={t("EDIT_LEAVE")}
                                    onClick={()=>EditLeave(employee_id, planData[key].leave_details, key)}>
                                </img>
                            }
                            {!planData[key]['leave'] && !!planData[key]['public_holiday'] &&
                                <img
                                    className="leave-cancel-icon pointer"
                                    src={planData[key]['public_holiday_icon']}
                                    alt="contract-hors"
                                    title={t("CANCEL_PUBLIC_HOLIDAY")}
                                    onClick={()=>deletePublicHoliday(employee_id, key, 1)}>
                                </img>
                            }
                            {!planData[key]['leave'] && !planData[key]['public_holiday'] && !!shiftLeave &&
                                <img
                                    className="edit-leave-icon pointer"
                                    src={AddLeaveIcon}
                                    alt="contract-hors"
                                    title={t("SHIFT_LEAVE")}
                                    // onClick={()=>deletePublicHoliday(employee_id, key, 1)}
                                    onClick={()=>EditLeave(employee_id, planData[key].leave_details, key)}>
                                </img>
                            }
                            {planData[key]['day_plans'].length !== 0 && <div className="float-right pointer" title={t("CONTRACT_HOURS")} onClick={()=>createPlan(employee_id, key, planData[key]['day_plans'], workstation)}>
                                <img className="planitem-icons mr-1 pointer" src={ContractIcon} alt="contract-hors"></img>{(planData[key]['total_shift_contract_hours']) ? planData[key]['total_shift_contract_hours'] : 0}
                            </div>}
                        </small>
                    </td>
                )
            }else{
                return(<>
                    <td key={key} id={planData[key] !== undefined && (planData[key]['leave'] || planData[key]['public_holiday']) ? "leave-background" : ''} onClick={()=>createPlan(employee_id, key, 0, workstation)} className={showAvailability && planData[key]!== undefined ? (planData[key]['available'] === 'available' ? 'available header-box-size p-2 pointer border-right' : (planData[key]['available'] !== 'not_specified' ? 'p-2 not-available header-box-size pointer border-right': "header-box-size p-2 pointer border-right")) : "header-box-size pointer p-2 border-right"}></td>
                    {planData[key] !== undefined && !!planData[key]['leave'] &&
                        <small className={"mb-0 pt-4 pb-3 d-flex unplanned-leave pointerplan-item-font"}>
                            <img
                                className={planData[key]['icon'] === "2" ? "leave-sun-icon pointer" : (planData[key]['icon'] === "1" || planData[key]['icon'] === "3" ? "edit-leave-icon pointer": "leave-cancel-icon pointer")}
                                src={planData[key]['icon'] === "1" ? SickLeaveIcon : (planData[key]['icon'] === "2" ? HolidayIcon : (planData[key]['icon'] === "3" ? AddLeaveIcon : OtherLeaveIcon))}
                                id="unplanned-leave"
                                alt="contract-hors"
                                title={t("EDIT_LEAVE")}
                                onClick={()=>EditLeave(employee_id, planData[key].leave_details, key)}>
                            </img>
                        </small>
                    }</>
                )
            }
        })
    )
}