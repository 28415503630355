import React, { useEffect, useState } from "react";
import { t } from "../../translations/Translation";
import "../../static/common.css";
import { getDatesByWeekNumber, GetFormattedDate, GetReversedDate } from "../../utilities/CommonFunctions";
import WeekHeader from "../atoms/WeekHeader";
import ModalPopup from "../../utilities/popup/Popup";
import AddPlan from "./AddPlan";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { GetWeeklyPlans, GetEmployees, DeleteWeeklyPlans, CreatePlan, GetWeeklyPlansWorkstations, GetWeeklyPlansCosts, CancelPublicHoliday } from "../../routes/ApiEndPoints";
import PlanRow from "../atoms/PlanRow";
import AddIcon from "../../static/icons/plus.png";
import EditIcon from "../../static/icons/edit.png";
import CostIcon from "../../static/icons/Euro.png";
import ContractIcon from "../../static/icons/Contract.png";
import Select from 'react-select';


export default function WeeklyTab(props) {

    const [planData, setPlanData] = useState([]);
    const [names, setNames] = useState(undefined);
    const [rowsData, setRowsData] = useState(localStorage.getItem('saved_workstations') ? JSON.parse(localStorage.getItem('saved_workstations')) : []);
    const [popupState, setPopupState] = useState(false);
    const [popupData, setPopupData] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [warningMessage, setWarningMessage] = useState('');
    const [dataRefresh, setDataRefresh] = useState(true);
    const [totalData, setTotalData] = useState([]);
    const [allWeekTotal, setAllWeekTotal] = useState('');
    const [deleteData, setDeleteData] = useState('');
    const [shiftPopup, setShiftPopup] = useState(false);
    const [displayshift, setDisplayShifts] = useState({});
    const [selectedShift, setSelectedShift] = useState([]);
    const [shiftStatus, setShiftStatus] = useState(false);
    const [publicHolidays, setPublicHolidays] = useState({});
    const [publicHolidayIcon, setPublicHolidayIcon] = useState('')
    const [holidaywarningMessage, setHolidaywarningMessage] = useState(false);
    const [deleteHolidayData, setDeleteHolidayData] = useState('');

    const regex = /(<([^>]+)>)/ig;
    let dates_array = [];
    let w = localStorage.getItem('week_number');
    let y = localStorage.getItem('year');
    dates_array = getDatesByWeekNumber();
    const [availableDates, setAvailableDates] = useState([]);
    const [unAvailableDates, setUnAvailableDates] = useState([]);
    const [remarkDates, setRemarkDates] = useState([]);
    const [savedWorkstations, setSavedWorkstations] = useState(localStorage.getItem('saved_workstations') ? JSON.parse(localStorage.getItem('saved_workstations')) : []);
    const [searchedEmployees, setSearchedEmployee] = useState(localStorage.getItem('saved_employees') ? JSON.parse(localStorage.getItem('saved_employees')) : []);
    let saved_employees = JSON.parse(localStorage.getItem('saved_employees'));
    let wids = {};
    let employee_types = {};
    if (localStorage.getItem('workstation')!== '' && localStorage.getItem('workstation') !== undefined && localStorage.getItem('workstation') !== 'null' && localStorage.getItem('workstation') !== null){
        let wsData = JSON.parse(localStorage.getItem('workstation'));
        for (let i=0; i<=wsData.length-1;i++){
            wids[i] = (wsData[i].value)
        }
    }
    if (localStorage.getItem('employee_type') !== '' && localStorage.getItem('employee_type') !== undefined && localStorage.getItem('employee_type') !== 'null' && localStorage.getItem('employee_type') !== null){
        let etData = JSON.parse(localStorage.getItem('employee_type'));
        for (let i=0; i<=etData.length-1;i++){
            employee_types[i] = (etData[i].value)
        }
    }

    function addTableRows(rowsInput){
        // const rowsInput= 2;
        setRowsData([...rowsData, rowsInput])
    }


    function deleteTableRows(index, eid, type, delete_eid, wid) {
        setWarningMessage('');

        let ws_id = JSON.stringify(wids)
        if (wid){
            ws_id = JSON.stringify({0:wid});
        }
        
        if (deleteData === ''){
            var data = {
                "lid"  : JSON.parse(localStorage.getItem('location')).value,
                "week_number" : localStorage.getItem('week_number'),
                "year" : localStorage.getItem('year'),
                "eid"  : eid,
                "token": localStorage.getItem('token'),
                "wids"  : ws_id,
                "employee_types": JSON.stringify(employee_types),
                "index": index
            }
            setDeleteData(data);
        } else {
            data = deleteData;
        }

        if (type !== 0)
        {
            const rows = [...rowsData];
            rows.splice(deleteData['index'], 1);
            setRowsData(rows);
            if (deleteData['eid'] !== 0) {
                AXIOS.service(DeleteWeeklyPlans, 'POST', data)
                .then((result)=>{
                    setDeleteData('');
                    setWarningMessage('');
                    if (result && result.status === 200){
                        setSuccessMessage(result.message);
                    } else if (result && result.status === 401){
                        props.CheckAuth();
                    } else{
                        setErrorMessage(result.message)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
            }
        } else {
            if (eid !== 0){
                setWarningMessage(t('CONFIRM_DELETE'))
            }else{
                const rows = [...rowsData];
                let saved_emp = JSON.parse(localStorage.getItem('saved_employees'))
                saved_emp.splice(index, 1)
                searchedEmployees.splice(index, 1);
                localStorage.setItem('saved_employees', JSON.stringify(saved_emp));
                rows.splice(index, 1);
                setRowsData(rows);
                availableDates.map((value, key) => {
                    if (value && value[delete_eid] !== undefined){
                        availableDates.splice(key, 1);
                    }
                })
                setAvailableDates(availableDates)
                remarkDates.map((value, key) => {
                    if (value && value[delete_eid] !== undefined){
                        remarkDates.splice(key, 1);
                    }
                })
                setRemarkDates(remarkDates);
                unAvailableDates.map((value, key) => {
                    if (value && value[delete_eid] !== undefined){
                        unAvailableDates.splice(key, 1);
                    }
                })
                setUnAvailableDates(unAvailableDates);
            }
        }
    }
   
    const deletePublicHoliday = (eid, date, type) => {
        if (type === 1 && deleteHolidayData === ''){
            setHolidaywarningMessage(!holidaywarningMessage);
            let data = {
                'eid'  : eid,
                'date' : GetReversedDate(date),
                'token': localStorage.getItem('token')
            }
            setDeleteHolidayData(data);
        }else{
            let data = deleteHolidayData;
            AXIOS.service(CancelPublicHoliday, 'POST', data)
            .then(result => {
                if (result.status === 200){
                    setHolidaywarningMessage(!holidaywarningMessage);
                    setSuccessMessage(result.message)
                }else{
                    setErrorMessage(result.message);
                }
            })
            .catch((error) => {
                console.log(error);
            })
        }
    }

    useEffect (() => {
        
        if (localStorage.getItem('location')){
            setPlanData([]);
            let requestData = {
                'year'  : y,
                'week_number': w,
                'lid'   : JSON.parse(localStorage.getItem('location')).value,
                "wids"  : JSON.stringify(wids),
                "employee_types": JSON.stringify(employee_types),
                "token" : localStorage.getItem('token'),
            }
            // AXIOS.service(GetWeeklyPlans, 'POST', requestData)
            // .then(result => {
            //     if (result && result.status === 200){
            //         setPlanData(result.data.planning_data);
            //         setTotalData(result.data.total_cost_contract_hours);
            //         setAllWeekTotal(result.data.total);
            //     } else if (result && result.status === 401){
            //         props.CheckAuth();
            //     } else {
            //         setPlanData([]);
            //         setTotalData([]);
            //     }
            // })
            AXIOS.service(GetWeeklyPlansWorkstations, 'POST', requestData)
            .then(result => {
                if (result && result.status === 200){
                    setPlanData(result.data);
                } else if (result && result.status === 401){
                    props.CheckAuth();
                } else {
                    setPlanData([]);
                }
            })
            .catch(error => {})
            AXIOS.service(GetWeeklyPlansCosts, 'POST', requestData)
            .then(result => {
                if (result && result.status === 200){
                    setTotalData(result.data.total_cost_contract_hours);
                    setAllWeekTotal(result.data.total);
                    setPublicHolidays(result.data.public_holidays)
                    setPublicHolidayIcon(result.data.public_holiday_icon)
                } else if (result && result.status === 401){
                    props.CheckAuth();
                } else {
                    setTotalData([]);
                }
            })
            .catch(error => {})
        }
    }, [dataRefresh, props.appliedFilterStatus, w, y, localStorage.getItem('location')]);

    useEffect(() =>{
        setRowsData(JSON.parse(localStorage.getItem('saved_workstations')))
    }, [w, y])

    useEffect(() =>{
        setAvailableDates([]);
        setUnAvailableDates([]);
        setRemarkDates([]);
        setSelectedShift([]);
        setDisplayShifts({});
        setPlanData([]);
        setTotalData([]);
    }, [w, y])

    useEffect(()=>{
            let data = {
                'lid'  : JSON.parse(localStorage.getItem('location')).value,
                "token": localStorage.getItem('token')
            }
            AXIOS.service(GetEmployees, 'POST', data)
            .then(result => {
                if (result && result.status === 200){
                    setNames(result.data);
                } else if (result && result.status === 401){
                    props.CheckAuth();
                } else {
                    setNames([])
                }
            })
            .catch(error => {
                // console.log(error);
            })
    }, [dataRefresh, props.appliedFilterStatus, w, y, localStorage.getItem('location')])

    function createPlan(employee_id, date, plans, workstation, shiftCreated){
        let shiftCreatedStatus = shiftCreated !== 1 ? (props.enableShifts ? false : true) : true; 
        setShiftStatus(true);
        let data = {
            'employee_id':employee_id,
            'plan_date': date,
            'workstation': workstation,
            'plans':plans,
        }
        
        if (employee_id && shiftCreatedStatus){
            setPopupState(true);
        } else if(employee_id === 0){
            shiftCreatedStatus = true
            setErrorMessage(t('EMPLOYEE_MISSING_ERROR'))
        }
        setPopupData(data)

        if (!shiftCreatedStatus) {
                let selectedLocation = JSON.parse(localStorage.getItem('location')).value;
                let shift_id = ''
                selectedShift.map((val, key) => { if (val[workstation] !== undefined) {shift_id = val[workstation]}})

                if (shift_id){

                    let data = {
                        "eid"      : employee_id,
                        "pdate"    : GetReversedDate(date),
                        "lid"      : selectedLocation,
                        "token"    : localStorage.getItem('token'),
                        "plan_data": JSON.stringify([]),
                        "shift_ids": shift_id,
                        "wid"      : workstation
                    }

                    AXIOS.service(CreatePlan, "POST", data)
                    .then(result => {
                        if (result && result !== [] && result.status === 200) {
                            if (result.data['created'] !== undefined && result.data['created']){
                                let data = {
                                    'employee_id':employee_id,
                                    'plan_date': date,
                                    'workstation': workstation,
                                    'plans':result.data.plan_data,
                                }
                                setPopupData(data);
                                setPopupState(true);
                            } else {
                                let saved_emp = JSON.parse(localStorage.getItem('saved_employees'))
                                let availableDates = availableDates === undefined ? [] : availableDates;
                                let unAvailableDates = unAvailableDates === undefined ? [] : unAvailableDates;
                                let remarkDates = remarkDates === undefined ? [] : remarkDates;
                                for(let i=0;i<=saved_emp.length-1;i++){
                                    if (saved_emp[i] && saved_emp[i][workstation] && saved_emp[i][workstation].value === employee_id){
                                        rowsData.splice(i, 1);
                                    }
                                }
                                for(let i=0;i<=saved_emp.length-1;i++){
                                    if (saved_emp[i] && saved_emp[i][workstation] && saved_emp[i][workstation].value === employee_id){
                                        saved_emp.splice(i, 1)
                                        availableDates.splice(i, 1); unAvailableDates.splice(i, 1); remarkDates.splice(i,1);
                                    }
                                }
                                localStorage.setItem('saved_employees', JSON.stringify(saved_emp));
                                setAvailableDates(availableDates);
                                setUnAvailableDates(unAvailableDates);
                                setRemarkDates(remarkDates);
                                setSearchedEmployee(saved_emp);
                                setShiftStatus(false);
                                setDataRefresh(!dataRefresh);
                            }
                        } else {
                            setErrorMessage(result.message);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                } else {
                    setErrorMessage(t("SELECT_SHIFT_ERROR"));
                }
        }
    }

    const onSuccessFunction = () => {
        setDataRefresh(!dataRefresh);
        // setRowsData([0]);
        props.setAddLeaveStatus(false)
        setShiftStatus(false);
        setSuccessMessage('');
    }

    const closePopUp = () => {
        // setDataRefresh(!dataRefresh);
        if (props.addLeaveStatus){
            props.setAddLeaveStatus(false)
            setDataRefresh(!dataRefresh);
        } else if (shiftPopup !== false){
            setShiftPopup(false)
            setDataRefresh(!dataRefresh);
        }else{
            setPopupState(false);
            setShiftStatus(false);
        }
        // setRowsData([0]);
    }

    function DeletePlan(planning_data, plan_id){
        if (plan_id === 0){
            setPopupState(popupState);
            setPopupData(planning_data);
        }else{
            let employee_type = planning_data.plans[0].employee_type
            let function_id = planning_data.plans[0].function_id
            
            for (let i = 0; i<=planning_data.plans.length-1; i++){
                if (planning_data.plans[i].plan_id === plan_id){
                    planning_data.plans.splice(i, 1);
                }
            }
            let data = {
                'employee_id':planning_data.employee_id,
                'plan_date': planning_data.plan_date,
                'workstation': planning_data.workstation,
                'plans':planning_data.plans,
                'employee_type': employee_type,
                'function_id': function_id
            }
            setPopupState(popupState);
            setPopupData(data);
        }
    }

    const customStyles = {
        valueContainer: (provided, state) => ({
          ...provided,
          height: 28,
          minHeight: 16,
          overflow:"auto",
        }),
      };

    const EditLeave = (emp_id, data, date) => {
        props.setAddLeaveStatus(true);
        props.setLeaveData(data)
    }

    const openShiftPopup = (workstation_id, shifts) => {
        setShiftPopup(workstation_id);
        if (shifts.length !== 0 && shifts!== undefined ){
            let data = {
                "wid":workstation_id,
                "shifts":shifts,
            }
            setShiftPopup(data);
        }
    }

    const SelectShift = (shift, wid) => {
        let data = {}
        data[wid] = shift.value
        setSelectedShift([...selectedShift ,data]);
        displayshift[wid] = shift
        setDisplayShifts(displayshift)
    }

    return (
            <div className="month-tab col-md-11 mx-auto px-4 pb-4 pt-0 bg-white">
                {(popupState || (props.enableShifts && shiftPopup !== false) || (props.enableShifts && selectedShift.length !== 0 && popupData && shiftStatus)) && <AddPlan
                    show={true}
                    onHide={closePopUp}
                    DeletePlan={DeletePlan}
                    popupData={popupData}
                    title={popupData.plans === 0 ? t("CREATE_PLAN"): t('UPDATE_PLAN')}
                    setSuccessMessage={setSuccessMessage}
                    rowsData = {rowsData}
                    setSearchedEmployee={setSearchedEmployee}
                    availableDates={availableDates}
                    unAvailableDates={unAvailableDates}
                    remarkDates={remarkDates}
                    addLeaveStatus={props.addLeaveStatus}
                    setAvailableDates={setAvailableDates}
                    setUnAvailableDates={setUnAvailableDates}
                    setRemarkDates={setRemarkDates}
                    names={names}
                    shiftPopup = {shiftPopup}
                    selectedShift={selectedShift}
                    createPlan={createPlan}
                    enableShifts={props.enableShifts}
                    setErrorMessagePopup={setErrorMessage}
                    popupState={popupState}
                    shiftStatus={shiftStatus}
                    setShiftStatus = { setShiftStatus}
                    setDataRefresh = {setDataRefresh}
                    dataRefresh = {dataRefresh}
                ></AddPlan>}
                {successMessage && <ModalPopup
                    title={t('SUCCESS')}
                    body={(successMessage)}
                    onHide={onSuccessFunction}
                ></ModalPopup>}
                {errorMessage && <ModalPopup
                    title={t('ERROR')}
                    body={(errorMessage)}
                    onHide={() => setErrorMessage('')}
                ></ModalPopup>}
                {warningMessage && <ModalPopup
                    title={t('WARNING')}
                    body={(warningMessage)}
                    deleteTableRows={deleteTableRows}
                    onHide={() => setWarningMessage('')}
                    buttonName={t('CANCEL')}
                ></ModalPopup>}
                {holidaywarningMessage && <ModalPopup
                    title={t('WARNING')}
                    body={t('DELETE_PUBLIC_HOLIDAY')}
                    deleteTableRows={deletePublicHoliday}
                    onHide={() => setHolidaywarningMessage(!holidaywarningMessage)}
                    buttonName={t('CANCEL')}
                ></ModalPopup>}
                <table className="table m-0 bg-white">
                    <thead className="sticky">
                        <WeekHeader days={dates_array} addTableRows={addTableRows} publicHolidays={publicHolidays} PublicHolidayIcon={publicHolidayIcon}></WeekHeader>
                    </thead>
                    {planData !== undefined && planData.length !== 0 && <tbody className="">
                        {planData !== undefined && planData.length !== 0 &&
                            Object.keys(planData).map((key) => {
                                return(
                                    <tr key={key} className="workstation-border">
                                        <td className="text-center border-top border-right px-1 text-vertical-center workstation-width" title={planData[key].workstation_name}>
                                            <p className="font-weight-bold mb-0">{planData[key].workstation_name.length > 10  ? planData[key].workstation_name.replace(regex, '').substring(0,11)+'..': planData[key].workstation_name}</p>
                                            <img src={AddIcon} title={t("ADD_NEW_ROW")} onClick={() => addTableRows(planData[key].workstation_id)} className="add-icon p-1 bg-light pointer" alt="add-new-employee"></img>
                                            {names !== undefined && props.enableShifts && 
                                            <div className="row m-0 col-12 mt-1 p-0 justify-content-center">
                                                <Select
                                                    placeholder= {t("SELECT_SHIFTS")}
                                                    value={displayshift[planData[key].workstation_id] ? displayshift[planData[key].workstation_id] : ''}
                                                    // value={(JSON.parse(localStorage.getItem('saved_employees')))[index] ? (JSON.parse(localStorage.getItem('saved_employees')))[index][workstation] : ''}
                                                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                                    onChange={(e) => SelectShift(e, planData[key].workstation_id)}
                                                    isMulti={false}
                                                    options={planData[key].shift_options}
                                                    required={true}
                                                    isDisabled={false}
                                                    isSearchable={false}
                                                    className="shift-dropdown pl-0 pr-1"
                                                    noOptionsMessage = {() => {t("NOT_FOUND")}}
                                                    styles={customStyles}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                        ...theme.colors,
                                                        primary25: '#1fe1258a',
                                                        primary: '#57BA5A',
                                                        },
                                                    })}
                                                />
                                                <img src={EditIcon} title={t("ADD_SHIFT")} onClick={() => openShiftPopup(planData[key].workstation_id, planData[key].shifts)} className="edit-icon my-auto bg-light pointer" alt="add-new-employee"></img>
                                            </div>
                                            }
                                        </td>
                                        <PlanRow 
                                            // planData={planData[key].employees}
                                            dates_array={dates_array}
                                            workstation={planData[key].workstation_id}
                                            showAvailability={props.showAvailability}
                                            createPlan={createPlan}
                                            deleteTableRows={deleteTableRows}
                                            names={names}
                                            rowsData={rowsData}
                                            popupState={popupState}
                                            refreshStatus = {props.refreshStatus}
                                            savedWorkstations={savedWorkstations}
                                            searchedEmployees={searchedEmployees}
                                            availableDates={availableDates}
                                            unAvailableDates={unAvailableDates}
                                            remarkDates={remarkDates}
                                            EditLeave = {EditLeave}
                                            enableShifts = {props.enableShifts}
                                            employee_types = {employee_types}
                                            appliedFilterStatus={props.appliedFilterStatus}
                                            shiftStatus={shiftStatus}
                                            displayshift={displayshift}
                                            dataRefresh={dataRefresh}
                                            deletePublicHoliday={deletePublicHoliday}
                                        ></PlanRow>
                                    </tr>
                                )
                            })
                        }
                        <tr className="bordr-bottom-0">
                            <td className="text-center text-vertical-center font-weight-bold border-top-0 workstation-width filter-title">{t('TOTAL')}</td>
                            <tr className="main-width row m-0 border-bottom-0">
                                <td className="employee-width border-right border-top-0"></td>
                                    {totalData !== undefined && totalData.length !== 0 &&
                                        Object.keys(totalData).map((val) =>{
                                            return(
                                                <td key={val} className="d-flex justify-content-between px-2 border-right border-top-0 header-box-size ">
                                                    {totalData[val].cost !== "" &&<small title={t("TOTAL_COST_DAY")}>
                                                        <img className="planitem-icons" src={CostIcon}></img>{totalData[val].cost}
                                                    </small>}
                                                    <small title={t('TOTAL_CONTRACT_HR_DAY')}>
                                                        <img className="planitem-icons" src={ContractIcon}></img>{totalData[val].contract_hours}
                                                    </small>
                                                </td>
                                            )
                                        })
                                    }
                                <td className="all-total-width border-top d-flex px-4 justify-content-between border-top-0">
                                    {allWeekTotal.cost !== "" && 
                                        <small className="font-weight-bold" title={t("TOTAL_COST")}>
                                            <img className="planitem-icons" src={CostIcon}></img>{allWeekTotal.cost}
                                        </small>
                                    } 
                                    <small title={t("TOTAL_CONTRACT_HR")}>
                                        <img className="planitem-icons" src={ContractIcon}></img>{allWeekTotal.contract_hours}
                                    </small>
                                </td>
                            </tr>
                        </tr>
                    </tbody>}
                    {planData === undefined &&
                    <tbody>
                        <tr className="text-center mt-2"><p>{t('NO_PLANS')}</p></tr>
                    </tbody>
                    }
                </table>
            </div>  
    );
}