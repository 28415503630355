// add all the constants here

import { t } from "../translations/Translation";

export const GET_CONSTANTS = {
    'WELCOME': 'Welcome',
    'MY_ACCOUNT': 'My account',
    'TO_BACKEND':'Switch to backend',
    'CHANGE_COMPANY':'Change company',
    'CHANGE_PASSWORD':'Change password',
    'LOGOUT':'Log out',
    'LOCATION':'Location',
    'WORKSTATION':'Work station',
    'WORKSTATIONS':'Workstations',
    'EMPLOYEE_TYPE':'Employee type',
    'FUNCTION':'Function',
    'FILTER':'FILTER',
    'LOCATION_PLACEHOLDER':'Select location',
    'WORKSTATION_PLACEHOLDER': 'Select work station',
    'EMPLOYEE_TYPES_PLACEHOLDER': 'Select employee type',
    'FUNCTION_PLACEHOLDER':'Select function',
    'NOT_FOUND':'Not found',
    'NO_LOCATION':'Please select location to get plans',
    'ERROR':'Error',
    'SUCCESS':'Success',
    'RELOAD':'Reload',
    'BACK':'Back',
    'HEAD_TITLE':'Weekly planning',
    'NO_ACCESS':'Unauthorized access',
    'AVAILABLE_SWITCH':'Show Availability for selected week',
    'MONTH':'MONTH',
    'WEEK':'WEEK',
    'DAY':'DAY',
    'EMPLOYEE':'Employee',
    'MONDAY': 'Monday',
    'TUESDAY': 'Tuesday',
    'WEDNESDAY': 'Wednesday',
    'THURSDAY': 'Thursday',
    'FRIDAY': 'Friday',
    'SATURDAY': 'Saturday',
    'SUNDAY': 'Sunday',
    'TOTAL' : 'Total',
    'ACTION':'Action',
    'PLANNING_TIME': 'Planning time',
    'LEAVE':'Leave',
    'NO_PLAN':"Plan doesn't exist in this location for the selected date",
    'LOAD_CHART':'Loading Chart',
    'CREATE_PLAN':"CREATE PLAN",
    'UPDATE_PLAN':'UPDATE PLAN',
    'WARNING':'Warning',
    'CANCEL': 'Cancel',
    'CONFIRM_DELETE':'Are you sure, you want to delete?',
    'TOTAL_COST':'Total cost',
    'TOTAL_CONTRACT_HR':'Total contract hours',
    'TOTAL_CONTRACT_HR_DAY': "Total contract hours per day",
    'TOTAL_COST_DAY':"Total cost per day",
    'NO_PLANS':'No plans exist',
    'REQUIRE_MSG':'Please enter all the required fields',
    'SAVE':'Save',
    'CLOSE':'Close',
    'NO_CANCEL':'No, Cancel',
    'START_TIME':'Start time',
    'END_TIME':'End time',
    'BREAK_TIME':'Break time',
    'CONTRACT_HOURS':'Contract hours',
    'WEEK_NUMBER': 'Week ',
    'JANUARY':'January',
    'FEBRUARY':'February',
    'MARCH':'March',
    'APRIL':'April',
    'MAY':'May',
    'JUNE':'June',
    'JULY':'July',
    'AUGUST':'August',
    'SEPTEMBER':'September',
    'OCTOBER':'October',
    'NOVEMBER':'November',
    'DECEMBER':'December',
    'YES_DELETE': 'Yes, Delete',
    'PLAN_TIMINGS':"Plan timings",
    'COST':'Cost',
    'WEEKLY_TOTAL_COST':"Weekly total cost",
    'WEEKLY_TOTAL_CONTRACT_HOURS':"Weekly total contract hours",
    'DELETE_ROW':"Delete row",
    'NAMES':"names",
    'HOME':"Home",
    'IMPORT_PLAN':"Import plans",
    'CLONE_PLAN':"Clone plans",
    'ADD_NEW_ROW':"Add new row",
    'SEARCH':'Search',
    'ADD_LEAVE':"ADD LEAVE",
    'UPDATE_LEAVE':"UPDATE LEAVE",
    'DATE':'Date',
    'HOLIDAY_CODES':'Holiday codes',
    'MULTIPLE_DAYS':'Multiple days',
    "MULTIPLE_HOLIDAY_CODE":"Multiple holiday codes",
    "REASON" : "Reason (Optional)",
    "FROM_DATE":"From date",
    "TO_DATE":"To date",
    "SELECT_IN_CALENDAR":"Select multiple days in calendar",
    "MULTIPLE_DATES":"Multiple dates",
    "HOURS":"Hours",
    "HOLIDAY_CODES_PLACEHOLDER":"Select holiday code",
    "SELECT_EMPLOYEE_PLACEHOLDER": "Select employees",
    "SHIFT_PLANNING":'Enable shift planning',
    "SELECT_SHIFTS":"Select shift",
    "ADD_SHIFT":"Add/update shifts",
    "CANCEL_LEAVE":"Cancel leave",
    "HALF_DAY":"Half day",
    "MORNING":"Morning",
    "EVENING":"Evening",
    "SELECT_SHIFT_ERROR": "Please select the shift to create plan",
    "EMPLOYEE_MISSING_ERROR": "Please select employee to create plan",
    "PLANS": "Select shifts to add leave",
    "DELETE_PUBLIC_HOLIDAY": "Are you sure, you want to delete the public holiday for this employee",
    "CANCEL_PUBLIC_HOLIDAY" : "Cancel public holiday",
    "EDIT_LEAVE":"Edit leave",
    "SHIFT_LEAVE":"Shift leave",
    "NO_SHIFT_SELECTED_ERROR":"Please select either morning or evening shift for half day leave",
    "MORNING_HOLIDAY_CODE":"Holiday code for morning shift",
    "EVENING_HOLIDAY_CODE":"Holiday code for evening shift"
};