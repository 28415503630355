import React, { useEffect, useState } from "react";
import { t } from "../../translations/Translation";
import "../../static/common.css";
import { useLocation } from "react-router-dom";
import Tab from "../atoms/NavItem";
import ImportIcon from "../../static/icons/Import.png";
import CloneIcon from "../../static/icons/Clone.png";
import AddLeaveIcon from "../../static/icons/addLeave.svg";
import { GetFormattedDate, GetReversedDate, getWeekNumberByDate } from "../../utilities/CommonFunctions";


export default function NavTabs(props) {
   
    
    const [isMonth, setIsMonth] = useState(false);
    const [isWeek, setIsWeek] = useState(false);
    const [isDay, setIsDay] = useState(false);
    let location = useLocation();
    const [value, setValue] = useState('');
    const months = [ "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", 
           "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER" ];

    let cid = JSON.parse(localStorage.getItem('location')).value;
    const cloneUrl = localStorage.getItem('domain') + '/' +  localStorage.getItem('active_language') + "/planning-overview/" + cid + "/clone-plannings"
    const importUrl = localStorage.getItem('domain') + '/' +  localStorage.getItem('active_language') + "/planning-overview/" + cid + "/import-plan"

    useEffect (() => {
        localStorage.setItem('saved_employees', '[]');
        localStorage.setItem('saved_workstations', '[]');
        if (location.hash === '#month'){
            setIsMonth(true);
            setIsWeek(false);
            setIsDay(false);
        }else if(location.hash === '#week' || location.hash === ''){
            setIsWeek(true);
            setIsDay(false);
            setIsMonth(false);
            let week = t('WEEK_NUMBER') + ' ' + localStorage.getItem('week_number');
            setValue(week)
        }else{
            setIsDay(true);
            setIsMonth(false);
            setIsWeek(false);
            let day = localStorage.getItem('date').split('-')[0]  + ' ' + t(months[localStorage.getItem('date').split('-')[1]-1]) + ', ' + localStorage.getItem('date').split('-')[2]; 
            setValue(day);
        }
    }, [value, localStorage.getItem('date'), location, localStorage.getItem('week_number'), localStorage.getItem('year')])

    const setNext = () => {
        localStorage.setItem('saved_employees', '[]');
        localStorage.setItem('saved_workstations', '[]');
        if (location.hash === '#week' || location.hash === '') {
            let val = parseInt(localStorage.getItem('week_number')) + 1;
            if (val > parseInt(localStorage.getItem('last_week'))){
                localStorage.setItem('week_number', 1);
                localStorage.setItem('year', parseInt(localStorage.getItem('year'))+1);
                let nextYear = parseInt(localStorage.getItem('year'))+1;
                localStorage.setItem('last_week', getWeekNumberByDate(nextYear+'-12-31'))
                setValue(1);
            } else {
                localStorage.setItem('week_number', JSON.stringify(val));
                setValue(val);
            }    
            props.RefreshTab();
        }else{
            let date = new Date(GetReversedDate(localStorage.getItem('date')));
            date.setDate(date.getDate() + 1);
            localStorage.setItem('date', GetFormattedDate(date));
            let day = GetFormattedDate(date).split('-')[0]  + ' ' + months[GetFormattedDate(date).split('-')[1] - 1] + ', ' + GetFormattedDate(date).split('-')[2]; 
            setValue(day);
            props.RefreshTab();
        }
    }

    const setPrev = () => {
        localStorage.setItem('saved_employees', '[]');
        localStorage.setItem('saved_workstations', '[]');
        if (location.hash === '#week' || location.hash === '') {
            let val = parseInt(localStorage.getItem('week_number')) - 1;
            if (val === 0){
                let prevYear = parseInt(localStorage.getItem('year'))-1;
                localStorage.setItem('year', prevYear);
                localStorage.setItem('week_number', getWeekNumberByDate(prevYear+'-12-31'));
                localStorage.setItem('last_week', getWeekNumberByDate(prevYear+'-12-31'))
                
                setValue(getWeekNumberByDate((parseInt(localStorage.getItem('year'))-1)+'-12-31'));
            } else {
                localStorage.setItem('week_number', JSON.stringify(val));
                setValue(val);
            }    
            props.RefreshTab();
        } else {
            let date = new Date(GetReversedDate(localStorage.getItem('date')));
            date.setDate(date.getDate() - 1);
            localStorage.setItem('date', GetFormattedDate(date));
            let day = GetFormattedDate(date).split('-')[0]  + ' ' + months[GetFormattedDate(date).split('-')[1] - 1] + ', ' + GetFormattedDate(date).split('-')[2]; 
            setValue(day);
            props.RefreshTab();
        }
    }


    return (
            <ul className="nav nav-tabs justify-content-center col-md-11 mt-1 pb-0 pt-2 px-4 bg-white mx-auto">
                <div className="col-md-3 d-flex mb-auto mt-0 p-0 float-left justify-content-start navigate">
                    {location.hash !== '#month' && <div className="d-flex left-nav">
                        <button className="font-navigate pointer" onClick={() => setPrev()}>{'‹'}</button>
                        <button className="font-navigate non-pointer">{value}</button>
                        <button className="font-navigate pointer" onClick={() => setNext()}>{'›'}</button>
                    </div>}
                </div>
                <Tab tabName = {t("MONTH")} tabHash="#month" isActive={isMonth}></Tab>
                <Tab tabName = {t("WEEK")} tabHash="#week" isActive={isWeek}></Tab>
                <Tab tabName = {t("DAY")} tabHash="#day" isActive={isDay}></Tab>
                <div className="col-md-3 d-flex p-0 float-right text-right justify-content-end">
                    <p className="col-md-2" onClick={() => props.OpenAddLeavePopup()}><img className="delete-icon mt-1 mb-2 pointer" src={AddLeaveIcon}  alt="import-plans" title={t("ADD_LEAVE")}></img></p>
                    <a className="col-md-2" href={importUrl}><img className="delete-icon mt-1 mb-2 pointer" src={ImportIcon}  alt="import-plans" title={t("IMPORT_PLAN")}></img></a>
                    <a className="col-md-2" href={cloneUrl}><img className="delete-icon mt-1 mb-2 pointer" src={CloneIcon}  alt="clone-plans" title={t("CLONE_PLAN")}></img></a>
                </div>
            </ul>  
    );
}