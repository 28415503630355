import React, { useEffect, useState } from "react";
import "../../static/common.css";
import NavTabs from "../molecules/NavTabs";
import MonthlyTab from "../molecules/MonthlyTab";
import WeeklyTab from "../molecules/WeeklyTab";
import DayTab from "../molecules/DayTab";
import { useLocation } from "react-router-dom";
import AddPlan from "../molecules/AddPlan";
import ModalPopup from "../../utilities/popup/Popup";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { GetEmployeesForLeave } from "../../routes/ApiEndPoints";
import { t } from "../../translations/Translation";


export default function PlanningTabs(props) {
    const [isActive, setIsActive] = useState("#month");
    const [refreshStatus, setRefreshStatus] = useState(false);
    const [addLeaveStatus, setAddLeaveStatus] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [names, setNames] = useState([]);
    let w = localStorage.getItem('week_number');
    let y = localStorage.getItem('year');
    let location = useLocation();
    const [updateLeaveData, setLeaveData] = useState([]);


    useEffect (() => {
        if (location.hash){
            setIsActive(location.hash);
        }else{
            setIsActive("#week");
        }
    })

    const RefreshTab = () => {
        setRefreshStatus(!refreshStatus);
    }

    const OpenAddLeavePopup = () => {
        setAddLeaveStatus(true)
    }

    useEffect(() => {

    }, [props])

    const closePopUp = () => {
        RefreshTab();
        setAddLeaveStatus(false);
        setLeaveData([]);
    }


    useEffect(()=>{
        let data = {
            "lid"  : JSON.parse(localStorage.getItem('location')).value,
            "token": localStorage.getItem('token'),
            "employee_type":JSON.stringify({0:"normal_employee"})
        }
        AXIOS.service(GetEmployeesForLeave, 'POST', data)
        .then(result => {
            if (result && result.status === 200){
                setNames(result.data);
            } else if (result && result.status === 401){
                props.CheckAuth();
            } else {
                setNames([])
            }
        })
        .catch(error => {
            // console.log(error);
        })
        
    }, [w, y, addLeaveStatus])

    
    return (
        <section className="pb-1">
            {addLeaveStatus && <AddPlan
                    show={true}
                    onHide={closePopUp}
                    popupData= {[]}
                    rowsData = {[]}
                    setSuccessMessage={setSuccessMessage}
                    addLeaveStatus={addLeaveStatus}
                    shiftPopup={false}
                    names={names}
                    availableDates={[]}
                    unAvailableDates={[]}
                    remarkDates={[]}
                    updateLeaveData={updateLeaveData}
            ></AddPlan>}
            {successMessage && <ModalPopup
                    title={t('SUCCESS')}
                    body={(successMessage)}
                    onHide={() => setSuccessMessage('')}
                ></ModalPopup>}
            <NavTabs RefreshTab={RefreshTab} OpenAddLeavePopup={OpenAddLeavePopup}></NavTabs>
            {isActive === "#month" && <MonthlyTab appliedFilterStatus={props.appliedFilterStatus} CheckAuth={props.CheckAuth} addLeaveStatus={addLeaveStatus}></MonthlyTab>}
            {isActive === "#week" && <WeeklyTab showAvailability={props.showAvailability} enableShifts={props.enableShifts} appliedFilterStatus={props.appliedFilterStatus} CheckAuth={props.CheckAuth} refreshStatus={refreshStatus} addLeaveStatus={addLeaveStatus} setAddLeaveStatus={setAddLeaveStatus} setLeaveData={setLeaveData}></WeeklyTab>}
            {isActive === "#day" && <DayTab appliedFilterStatus={props.appliedFilterStatus} CheckAuth={props.CheckAuth} refreshStatus={refreshStatus} addLeaveStatus={addLeaveStatus} ></DayTab>}
        </section>
  );
}