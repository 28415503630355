import React, { useEffect }  from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetTranslatedConstants } from "../translations/Translation";


export default function Authenticate() {

    let params = useParams();
    const navigate = useNavigate();

    // Saving token of the employee for the selected company and domain for authentication
    // After saving the details redirecting to weekly planning overview
    useEffect(() => {
        if (params){

            if (params.domain === 'test-ava') {
                localStorage.setItem('domain', 'https://test.ava.infanion.com');
                localStorage.setItem('api_domain', 'https://test.ava.infanion.com');
            } else if (params.domain === 'uat-ava') {
                localStorage.setItem('domain', 'https://uat.ava.infanion.com');
                localStorage.setItem('api_domain', 'https://uat.ava.infanion.com');
            } else if (params.domain === 'ava') {
                localStorage.setItem('domain', 'https://henri.ava.be');
                localStorage.setItem('api_domain', "https://api.henri.ava.be");
            } else if (params.domain === 'hotfix-ava') {
                localStorage.setItem('domain', 'https://hotfix.ava.infanion.com');
                localStorage.setItem('api_domain', 'https://hotfix.ava.infanion.com');
            } else {
                localStorage.setItem('domain', 'http://ava.local');
                localStorage.setItem('api_domain', "http://ava.local");
            }

            localStorage.removeItem('location');
            localStorage.removeItem('workstation');
            localStorage.removeItem('employee_type');
            localStorage.setItem('week_number', localStorage.getItem('current_week'));
            localStorage.setItem('date', localStorage.getItem('current_date'));
            localStorage.setItem('token', params.token);
    
            let translations = {'en':{}, 'nl':{}, 'fr':{}}
            localStorage.setItem('translations', JSON.stringify(translations))
            
            GetTranslatedConstants()
        }
        if (localStorage.getItem('domain')){
            if (params.lang == 'en'){
                navigate('/en')
                window.location.reload()
            }else if (params.lang == 'fr'){
                navigate('/fr')
                window.location.reload()
            }else{
                navigate('/nl')
                window.location.reload()
            }}
    })
    
  return (
        <h2 className="text-center font-success">Authenticating....</h2>
  );
}