import React, { useEffect, useState } from "react";
import { t } from "../translations/Translation";
import "../static/common.css";
import Header from "../components/molecules/Header";
import FiltersForCompany from "../components/organisms/FiltersForCompany";
import PlanningTabs from "../components/organisms/PlanningTabs";
import Switch from "../components/atoms/Switch";
import { GetEmployeeDetails } from "../routes/ApiEndPoints";
import { APICALL as AXIOS } from "../services/AxiosServices";
import { useLocation } from "react-router-dom";
import ModalPopup from "../utilities/popup/Popup";
import BackIcon from "../static/icons/Back.png";

// planning overview page with header, filters and all 3 tabs
export default function PlanningOverview() {
    const companyId = 2;
    const [company, setCompany] = useState();
    const [employee, setEmployee] = useState();
    const [showAvailability, setShowAvailability] = useState(false);
    const [appliedFilterStatus, setAppliedFilterStatus] = useState(false);
    const [enableShifts, setEnableShifts] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();

    const checkAvailability = (e) => {
        setShowAvailability(e.target.checked);
    }
    const EnableShifts = (e) => {
        setEnableShifts(e.target.checked);
    }

    const addFilters = () => {
        setAppliedFilterStatus(!appliedFilterStatus)
    }

    const CheckAuth = () => {
        setErrorMessage(t('NO_ACCESS'));
    }

    const RedirectBackToIndii = () => {
        window.location.href=localStorage.getItem('domain') + "/api/generate_authentication_token";
    }

    useEffect(() => {
        if (localStorage.getItem('token') === null || localStorage.getItem('domain') === null){
            localStorage.setItem('domain', 'https://henri.ava.be');
            localStorage.setItem('api_domain', "https://api.henri.ava.be");
            CheckAuth();
            //window.location.href=localStorage.getItem('domain') + "/api/generate_authentication_token";
        }
    }, [])

    useEffect(()=>{
        let data = {
            "token": localStorage.getItem('token')
        }
        AXIOS.service(GetEmployeeDetails, "POST", data)
        .then((result)=>{
            if (result && result.status === 200 ){
                setCompany(result.data['parent_company_name']);
                setEmployee(result.data['full_name']);
            } else if(result && result.status === 401){
                CheckAuth();
            }
        })
        .catch((error)=>{

        })
    }, [errorMessage])


    useEffect(() =>{
        setShowAvailability(false);
    }, [location])
    
  return (
        <section>
            {errorMessage && 
                <ModalPopup 
                    title={t('ERROR')}
                    body={(errorMessage)}
                    buttonName={t('RELOAD')}
                    onHide={() => RedirectBackToIndii()}
                ></ModalPopup>
            }
            <Header company={company} employee={employee} ></Header>

            <div className="d-flex col-md-11 p-0 mx-auto">
                <a href={localStorage.getItem('domain') + '/' +  localStorage.getItem('active_language') + "/home2"} className="col-md-1 pl-0 text-left title-color">
                    <p className="mb-0 pointer"><img src={BackIcon} className="back-icon"></img>&nbsp;{t('BACK')}</p>
                </a>
                <h3 className="col-md-11 head-title title-color font-weight-bold text-center mb-0">{t("HEAD_TITLE")}</h3>
            </div>

            <FiltersForCompany companyId = {companyId} addFilters={addFilters} CheckAuth={CheckAuth}></FiltersForCompany>

            <Switch checkAvailability={checkAvailability} EnableShifts={EnableShifts}></Switch>

            { localStorage.getItem('location') && 
                <PlanningTabs
                    className="col-md-11"
                    showAvailability={showAvailability}
                    enableShifts={enableShifts}
                    appliedFilterStatus={appliedFilterStatus}
                    CheckAuth={CheckAuth}
                ></PlanningTabs>
            }
            { !localStorage.getItem('location') && 
                <div className="col-md-11 border text-center py-4 my-5 text-danger border-dark mx-auto">
                    <p>{t('NO_LOCATION')}</p>
                </div>
            }
        </section>
  );
}
