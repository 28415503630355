import React, {useEffect, useState} from "react";
import {t} from "../../translations/Translation";
import Button from 'react-bootstrap/Button';
import FilterDropdown from "./FilterDropdown";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { GetHolidayCodes, AddLeaveByPlanApi, CancelLeave } from "../../routes/ApiEndPoints";
import { Alert, Modal } from "react-bootstrap";

export default function AddLeaveByPlan(props){

    const [singleHolidayCode, setSingleHolidayCode] = useState(props.leaveDetails.shift_leave_data ? props.leaveDetails.shift_leave_data.holiday_code : "");
    const [holidayCode, setHolidayCode] = useState(props.leaveDetails.shift_leave_data ? props.leaveDetails.shift_leave_data.holiday_code.value : '');
    const [holidayCodesList, setHolidayCodesList] = useState([]);
    const [reason, setReason] = useState(props.leaveDetails.shift_leave_data ? props.leaveDetails.shift_leave_data.reason : '');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        let data = {
            "lid": JSON.parse(localStorage.getItem('location')).value,
            "eid": props.eid,
            "token": localStorage.getItem("token")
        }
        AXIOS.service(GetHolidayCodes, "POST", data)
        .then((result)=>{
            if (result && result.status === 200 ){
                let allHolidayCodes = (result.data.day_codes).concat(result.data.hour_codes);
                allHolidayCodes.sort(function(a, b){
                    if(a.label < b.label) { return -1; }
                    if(a.label > b.label) { return 1; }
                    return 0;
                })
                setHolidayCodesList(allHolidayCodes);
            }else{
                setHolidayCodesList([])
            }
        })
        .catch((error)=>{

        })
    }, [])


    const SetLeaveData = (type, data) => {
        switch(type){
            case 1:
                setHolidayCode(data)
                break;
            case 2:
                setReason(data)
                break;
        }
    }

    const SaveLeave = () => {
        //Api call
        let data = {
            'pid': props.pid,
            'holiday_code': holidayCode,
            'reason': reason,
            'leave_id': props.leaveDetails.shift_leave_data ? props.leaveDetails.shift_leave_data.leave_id : '',
            "token": localStorage.getItem("token")
        }

        AXIOS.service(AddLeaveByPlanApi, "POST", data)
        .then((result)=>{
            if (result && result.status === 200 ){
                props.setSuccessMessage(result.message)
                props.onHide();
            }else{
                //show error
                setErrorMessage(result.message)
            }
        })
        .catch((error)=>{

        })

    }


    const DeleteLeave = () => {
        let data = {
            "eid": props.eid,
            "pid": props.pid,
            "leave_id": props.leaveDetails.shift_leave_data ? props.leaveDetails.shift_leave_data.leave_id : '',
            "token": localStorage.getItem('token')
        }
        AXIOS.service(CancelLeave, 'POST', data)
            .then((result) => {
                if (result.status === 200){
                    props.setSuccessMessage(result.message);
                    props.onHide();
                }else{
                    setErrorMessage(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return(
        <Modal
            show={true}
            onHide={props.onHide}
            size={"md"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className=''
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter" className='mx-auto title-color font-weight-bold'>
                    {t("ADD_LEAVE")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='m-4 border pt-0'>
                {errorMessage && <Alert variant='danger' onClose={()=>setErrorMessage('')} dismissible>{(errorMessage)}</Alert>}
                <form className="p-2 pl-1">
                        <div className="title-position">
                            <FilterDropdown 
                                filterName={t("HOLIDAY_CODES")}
                                options={holidayCodesList}
                                placeHolder={t("HOLIDAY_CODES_PLACEHOLDER")}
                                isMulti={false}
                                selectedOption={singleHolidayCode}
                                setSelectedOption={(e) => SetLeaveData(1, e.value)}
                                required={true}
                                isDisabled={true}
                            >
                            </FilterDropdown>
                        </div>
                        <div className="form-group mt-2">
                            <label for="comment" className="filter-title">{t("REASON")}</label>
                            <textarea className="form-control form-fields" rows="5" id="comment" value={reason} onChange={(e) => SetLeaveData(2, e.target.value)}></textarea>
                        </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                {props.leaveDetails.shift_leave_data !== undefined && props.leaveDetails.shift_leave_data.length !== 0 && <Button className='button-style' onClick={(e)=>DeleteLeave()}>
                    {t('CANCEL_LEAVE')}
                </Button>}
                <Button className='button-style' onClick={(e)=>SaveLeave()}>
                    {t('SAVE')}
                </Button>
                <Button className='button-style' onClick={props.onHide}>
                    {t('CLOSE')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}